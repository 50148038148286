import { takeEvery, call, put } from 'redux-saga/effects';
import { saveContact } from '../Services/API';
import types from '../ActionTypes';
import actions from '../Actions';

function* callSaveContact(action){
    yield put(actions.saveContactStarted());
    const response = yield call(saveContact, action.payload.contact);
    yield put(actions.saveContactCompleted(response.data));
}

export function* saveContactSaga() {
    yield takeEvery(types.SAVE_CONTACT, callSaveContact);
}