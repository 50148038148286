import types from '../../Store/ActionTypes';

const initialState = {
    products: [],
    notification: null,
    loaded: false,
    count: 0
}

const productsReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case types.SET_PRODUCTS:
            return {
                ...state,
                products: action.payload.products,
                notification: action.payload.notification,
                count: action.payload.productCount,
                loaded: true
            }

        case types.FETCH_PRODUCTS:
            return {
                products: [],
                notification: null,
                loaded: false,
                count: 0
            }

        default:
            return state;
    }
}

export default productsReducer;