import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

import { Spinner } from 'reactstrap';

import actions from '../../Store/Actions';

const LandingPage = (props) => {
    // need to grab the splash/landing html from service
    const dispatch = useDispatch();
    const history = useHistory();

    const landingPage = useSelector((state) => state.landingPage);
    const organizationId = useSelector((state) => state.organizationId.organizationId);
    const common = useSelector((state) => state.common);

    const [loading, setLoading] = useState(true);
    const [content, setContent] = useState('Content not specified');

    const updateContent = (dirty) => {
        setContent(DOMPurify.sanitize(dirty, { USE_PROFILES: { html: true } }));
    };

    // loading, call landing page service
    useEffect(() => {
        if (loading && organizationId && common.organization.hasLandingPage) {
            setLoading(false);
            dispatch(actions.getLandingPage({ organizationId: organizationId }));
        }
    }, [loading, organizationId, dispatch, common]);

    // landing page reducer updated, set content
    useEffect(() => {
        if (landingPage.complete) {
            if (landingPage.content) {
                updateContent(landingPage.content);
            }
        }
    }, [landingPage, history]);

    return (
        <div>
            {loading && <Spinner color="primary" style={{ width: '6rem', height: '6rem' }} />}
            {!loading && parse(content)}
        </div>
    );
};

export default LandingPage;
