import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../Store/Actions';

import { Row, Col, Button, Spinner, Modal, ModalBody, ModalFooter, ModalHeader, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import Icon from '../Icon/icon';
import Toolbar from '../../Views/Toolbar/Toolbar';
import MyListProductCard from '../Product/MyListProductCard';
import MyListRepCard from '../Reps/MyListRepCard';
import MyListOrganizationCard from '../Organization/MyListOrganizationCard';

class MyListPage extends Component {
    constructor(props) {
        super(props);
        this.toggleConfirm = this.toggleConfirm.bind(this);
        this.clearListClickHandler = this.clearListClickHandler.bind(this);
        this.state = {
            confirm: false,
        };
    }

    toggleConfirm() {
        this.setState((state) => ({ confirm: !state.confirm }));
    }

    clearListClickHandler() {
        this.props.clearMylist();
        this.clearLocalStore();
        this.toggleConfirm();
    }

    clearLocalStore = () => {
        localStorage.removeItem('mylist');
    };

    requestInfoClickHandler = () => {
        let currentRep = 'mds';
        if (this.props.currentRep) {
            currentRep = this.props.currentRep.siteName;
        }
        this.props.history.push(`/${currentRep}/more-info`);
    };

    render() {
        return (
            <React.Fragment>
                <Toolbar showBrowse />
                <Row>
                    <Col sm="16">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">MyList</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {this.props.count > 0 ? (
                                    this.props.mylist.map((product) => <MyListProductCard key={product.productId} product={product} showDescription />)
                                ) : (
                                    <h5 className="text-center m-5">There are no products in MyList.</h5>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="8">
                        {this.props.commonLoaded ? (
                            <MyListOrganizationCard organization={this.props.common.organization} />
                        ) : (
                            <div className="text-center my-2">
                                <Spinner color="primary" />
                            </div>
                        )}
                        {this.props.currentRep && <MyListRepCard currentRep={this.props.currentRep} />}
                        <Button color="success" block onClick={() => this.requestInfoClickHandler()} className="mb-2">
                            <Icon icon="info-circle" /> Request Information
                        </Button>
                        <Button color={this.props.count === 0 ? 'secondary' : 'danger'} disabled={this.props.count === 0} onClick={this.toggleConfirm} block>
                            <Icon icon="trash" /> Clear List
                        </Button>
                    </Col>
                </Row>
                <Modal isOpen={this.state.confirm} toggle={this.toggleConfirm} style={{ zIndex: 1051 }}>
                    <ModalHeader toggle={this.toggleConfirm}>Confirm</ModalHeader>
                    <ModalBody>Are you sure you want to remove all products from MyList?</ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.clearListClickHandler}>
                            Yes, Clear List Now
                        </Button>
                        <Button color="success" onClick={this.toggleConfirm}>
                            No, Keep List
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        mylist: state.myList.mylist,
        count: state.myList.count,
        currentRep: state.rep.rep,
        common: state.common,
        commonLoaded: state.common.loaded,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            clearMylist: actions.clearMylist,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyListPage);
