import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchProducts, getSentList } from '../Services/API';
import types from '../ActionTypes';
import actions from '../Actions';

function* getProducts(action) {
    const response = yield call(fetchProducts, action.payload.params);
    yield put(actions.setProducts(response.data));
}

export function* productsSaga() {
    yield takeLatest(types.FETCH_PRODUCTS, getProducts);
}

function* getSentListSaga(action) {
    const response = yield call(getSentList, action.payload.params);
    yield put(actions.setSentList(response.data));
}

export function* sentListSaga() {
    yield takeLatest(types.GET_SENT_LIST, getSentListSaga);
}
