import React, { Component } from 'react';
import { connect } from 'react-redux'; 
import { bindActionCreators } from 'redux';
import actions from '../../Store/Actions';
import {
    Button
} from 'reactstrap';

import Icon from '../Icon/icon';

class ProductToggle extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          inMylist: false
        };
      }

    alreadyInList = () => {
        let mylist = this.props.mylist;
        for (let i = 0; i < mylist.length; i++) {
            const product = mylist[i];
            if(product.productId === this.props.product.productId){
                return true;
            }
        }
        return false;
    }

    mylistClickHandler = () => {
        let mylist = this.props.mylist;
        if(!this.alreadyInList()){
            mylist.push(this.props.product);
            this.addProductToLocalStore(this.props.product);
            //get products from store
            let data = this.props.products;
            for (let i = 0; i < data.products.length; i++) {
                const product = data.products[i];
                if(product.productId === this.props.productId) {
                    data.products[i].inMyList = true;
                    break;
                }
            }
            this.props.setProducts(data);
            this.props.setToMylist({mylist: mylist});
            this.setState({
                inMyList: true
            });
        }
    }

    mylistRemoveClickHandler = () => {
        let mylist = this.props.mylist;
        for (let i = 0; i < mylist.length; i++) {
            const product = mylist[i];
            if(product.productId === this.props.product.productId) {
                mylist.splice(i,1);
                this.props.setToMylist({mylist: mylist});
                break;
            }
        }
        let data = this.props.products;
        for (let i = 0; i < data.products.length; i++) {
            const product = data.products[i];
            if(product.productId === this.props.productId) {
                data.products[i].inMyList = false;
                this.props.setProducts(data);
                break;
            }
        }
        this.removeProductFromLocalStore(this.props.product.productId)

        this.setState({
            inMyList: false
        });
    }

    removeProductFromLocalStore = (productId) => {
        let mylist = localStorage.getItem('mylist');
        if(mylist){
            mylist =JSON.parse(mylist);
            for (let i = 0; i < mylist.length; i++) {
                const product = mylist[i];
                if(product.productId === productId){
                    mylist.splice(i,1);
                    localStorage.setItem('mylist', JSON.stringify(mylist));
                    break;
                }
            }
        }
    }

    addProductToLocalStore = (product) => {
        let mylist = localStorage.getItem('mylist');
        if(!mylist){
            mylist = [];
        } else {
            mylist =JSON.parse(mylist);
        }
        mylist.push(product);
        localStorage.setItem('mylist', JSON.stringify(mylist));
    }

    render () {
        let button = null;
        button = this.alreadyInList() || this.state.inMylist
                    ?
                    <Button color="danger" outline size="sm" onClick={() => this.mylistRemoveClickHandler()} className={this.props.className}>
                        <Icon icon="minus-circle" /> Remove
                    </Button>
                    :
                    <Button color="success" size="sm" onClick={() => this.mylistClickHandler()} className={this.props.className} title='Add to MyList'>
                        <Icon icon="plus-circle" /> MyList
                    </Button>;
        return(
            <React.Fragment>
            {button}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
      mylist: state.myList.mylist,
      products: state.products
    }
  }

  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        setToMylist: actions.setToMylist,
        setProducts: actions.setProducts
    }, dispatch);
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ProductToggle);