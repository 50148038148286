
export function fieldValidCheck(fieldName) {
    let field = document.getElementById(fieldName);
    if (!(field.checkValidity())) {
        field.focus();
        return false;
    }
    return true;
}

export function emailValidCheck(fieldName) {
  let field = document.getElementById(fieldName);
  if (!(field.checkValidity())
  || !(/\S+@\S+\.\S+/.test(field.value))
  ) {
      field.focus();
      return false;
  }
  return true;
}

export function isEmailPattern(x) {
  return /\S+@\S+\.\S+/.test(x);
}

export function Money(x) {
    if (!x) {
      return '$0.00';
    }
    return x.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
}

export function isDigits(x) {
  return '' === x || /^[0-9]+$/.test(x);
}

export function isFloat(x) {
  return '' === x || (/^[.0-9\b]+$/.test(x) && (x.match(/[.]/g) || []).length < 2);
}

export function makeAlphaNumeric(x) {
  return x.replace(/[^0-9A-Z]+/gi,"");
}