import React from 'react';
import classNames from 'classnames';

const ToggleControl = ({ id, name, label, className, checked, disabled, invalid, isToggleSwitch, labelClassName, wrapperClassName, ...attributes }) => {
    return (
        <div
            className={classNames('form-check', isToggleSwitch ? 'form-switch' : '', disabled ? '' : 'pointer', invalid ? 'text-danger' : '', wrapperClassName)}
        >
            <input
                className={classNames(className, 'form-check-input', disabled ? '' : 'pointer')}
                type="checkbox"
                id={id}
                name={name}
                checked={checked}
                disabled={disabled}
                {...attributes}
            />
            {label ? (
                <label className={classNames('form-check-label', disabled ? '' : 'pointer', labelClassName)} htmlFor={id}>
                    {label}
                </label>
            ) : null}
        </div>
    );
};

export default ToggleControl;
