import React from 'react';

import { Modal, ModalHeader, ModalFooter, ModalBody, Row, Col, Card, CardHeader, CardTitle, CardImg, CardBody, UncontrolledTooltip, Badge } from 'reactstrap';
import ProductToggle from '../MyList/ProductToggle';
import ProductInfoButton from '../Product/productInfoButton';
import { IMAGE_PATH } from '../Shared/Constants/Constants';

const productQuickViewModal = (props) => {
    let billingCodes = null;
    let productImage = null;
    let productDescription = null;
    let mainimage = null;
    if (props.product) {
        if (props.product.productBillingCodes && props.product.productBillingCodes.length > 0) {
            billingCodes = props.product.productBillingCodes.map((code) => {
                return (
                    <Row key={code.productBillingCodeId}>
                        <Col sm="24" className="border-bottom mb-2">
                            <h5>
                                {code.billingCode}{' '}
                                <Badge color="dark" id={`bc-${code.productBillingCodeId}`}>
                                    {code.billingTypeCode}
                                </Badge>
                            </h5>
                            <UncontrolledTooltip target={`bc-${code.productBillingCodeId}`}>{code.billingTypeName}</UncontrolledTooltip>
                        </Col>
                    </Row>
                );
            });
        } else {
            billingCodes = (
                <Row>
                    <Col md="24">N/A</Col>
                </Row>
            );
        }
        mainimage = props.product.mainPhotoUrl ? `${IMAGE_PATH}${props.product.mainPhotoUrl}?format=jpg&height=350&quality=80` : '/images/no_image.jpg';
        productImage = <CardImg width="100%" src={mainimage} className="border" />;
        productDescription = { __html: props.product.productDescription };
    }
    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} size="lg">
            <ModalHeader toggle={props.toggle}>{props.product && props.product.productNameTlc}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm="8">
                        <Card className="shadow-sm mb-3">
                            <CardBody>{productImage}</CardBody>
                        </Card>
                        <Card className="shadow-sm mb-3">
                            <CardHeader>
                                <CardTitle tag="h5">Suggested Insurance Billing Codes</CardTitle>
                            </CardHeader>
                            <CardBody>{billingCodes}</CardBody>
                        </Card>
                    </Col>
                    <Col sm="16">
                        <Card className="shadow-sm mb-3">
                            <CardHeader>
                                <CardTitle tag="h5">Product Description</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <p dangerouslySetInnerHTML={productDescription} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <ProductToggle product={props.product} />
                <ProductInfoButton productId={props.product ? props.product.productId : 0} productName={props.product ? props.product.productName : ''} />
            </ModalFooter>
        </Modal>
    );
};

export default productQuickViewModal;
