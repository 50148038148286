import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';
import { apiGetQuote, apiDeclineQuote } from '../Services/API';
import types from '../ActionTypes';
import actions from '../Actions';

// get quote
function* callGetQuote(action) {
    const response = yield call(apiGetQuote, action.payload.supplierId, action.payload.quoteId);
    yield put(actions.setQuote(response.data));
}
export function* getQuoteSaga() {
    yield takeLatest(types.GET_QUOTE, callGetQuote);
}

// decline quote
function* callDeclineQuote(action){
    const response = yield call(apiDeclineQuote, action.payload.quoteResponse);
    yield put(actions.declineQuoteComplete(response.data));
}
export function* declineQuoteSaga() {
    yield takeEvery(types.DECLINE_QUOTE, callDeclineQuote);
}
