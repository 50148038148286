import types from '../../Store/ActionTypes';

const initialState = {
    product: null,
    notification: null,
    loaded: false
}

const productReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case types.SET_PRODUCT:
            return {
                ...state,
                product: action.payload.product,
                notification: action.payload.notification,
                loaded: true
            }

        case types.FETCH_PRODUCT:
            return {
                product: null,
                notification: null,
                loaded: false
            }

        default:
            return state;
    }
}

export default productReducer;