import types from '../../Store/ActionTypes';

const initialState = {
    rep: null
}

const repReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_REP:
            return {
                ...state,
                rep: action.payload.rep
            }
        
        default:
            return state;
    }
}

export default repReducer;