const hostname = window && window.location && window.location.hostname;
let api_root = 'api.orthotom.com';
let env = null;

if (hostname.includes('alpha')) {
    api_root = 'api-alpha.orthotom.com';
    env = 'ALPHA';
} else if (hostname.includes('beta')) {
    api_root = 'api-beta.orthotom.com';
    env = 'BETA';
} else if (hostname.includes('staging')) {
    api_root = 'api-staging.orthotom.com';
    env = 'STAGING';
} else if (hostname.includes('localhost')) {
    api_root = 'localhost:44356';
    env = 'DEV';
}

export const Api_Root = `https://${api_root}/prp/`;
export const ENV = env;
