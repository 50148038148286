import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../Store/Actions';
import { Card, CardBody, Row, Col, CardHeader, CardTitle, CardSubtitle, Button } from 'reactstrap';
import Icon from '../Icon/icon';
import Style from './RepCard.module.css';
import { IMAGE_PATH } from '../Shared/Constants/Constants';

class RepCard extends Component {
    setCurrentRep = (rep) => {
        this.props.setRep({ rep: rep });
        this.props.history.push(`/${rep.siteName}`);
    };

    render() {
        const rep = this.props.rep;
        return (
            <Card className={`float-start me-2 mb-2 shadow-sm ${Style.RepCard}`} style={this.props.style}>
                <CardHeader className="p-2">
                    <CardTitle tag="h4" className="mb-1">
                        {rep.fullName}
                    </CardTitle>
                    <CardSubtitle className="ms-1 text-muted">{rep.jobTitle ? rep.jobTitle : 'Sales Rep'}</CardSubtitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col xs="8">
                            {rep.photoUrl ? (
                                <img
                                    width="100%"
                                    alt={rep.fullName}
                                    src={`${IMAGE_PATH}${rep.photoUrl}?anchor=middlecenter&mode=crop&format=jpg&height=100&width=100&quality=80`}
                                    style={{ maxWidth: 100 }}
                                    className="rounded"
                                />
                            ) : (
                                <img
                                    width="100%"
                                    alt={rep.fullName}
                                    src={`https://images.orthotom.com/images/apps/no-profile-img.jpg?anchor=middlecenter&mode=crop&format=jpg&height=100&width=100&quality=80`}
                                    style={{ maxWidth: 100 }}
                                    className="rounded"
                                />
                            )}
                        </Col>
                        <Col xs="16">
                            <Row className="mb-1">
                                <Col>
                                    <span className="text-secondary me-2">
                                        <Icon icon="map-marker-alt" size="lg" />
                                    </span>
                                    {rep.region}
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col>
                                    <span className="text-secondary me-2">
                                        <Icon icon="phone" size="lg" />
                                    </span>
                                    <a href={`tel:${this.props.rep.phone}`}>{this.props.rep.phone}</a>
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col>
                                    <span className="text-secondary me-2">
                                        <Icon icon="envelope" size="lg" />
                                    </span>
                                    <a href={`mailto:${this.props.rep.email}`}>{this.props.rep.email}</a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span className="text-secondary me-2">
                                        <Icon icon="globe" size="lg" />
                                    </span>
                                    <Button color="link" onClick={() => this.setCurrentRep(this.props.rep)} className="p-0">
                                        Visit {this.props.rep.firstName}'s Page
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentRep: state.rep.rep,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            setRep: actions.setRep,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RepCard));
