import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'; 
import { bindActionCreators } from 'redux';
import actions from '../../Store/Actions';

import {
    Modal,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button
} from 'reactstrap';
import Icon from '../Icon/icon';
import MyListProductCard from '../Product/MyListProductCard';

class MyListModal extends Component {

    constructor(props) {
        super(props);
        this.toggleConfirm = this.toggleConfirm.bind(this);
        this.clearListClickHandler = this.clearListClickHandler.bind(this);
        //this.requestInfoClickHandler = this.requestInfoClickHandler.bind(this);
        this.state = {
            confirm: false
        };
    }

    toggleConfirm() {
        this.setState(state => ({ confirm: !state.confirm }));
    }

    clearListClickHandler () {
        this.props.clearMylist();
        this.clearLocalStore();
        this.toggleConfirm();
    }

    clearLocalStore = () => {
        localStorage.removeItem('mylist');
    }

    requestInfoClickHandler = () => {
        this.props.toggle();
        let currentRep = 'mds';
        if(this.props.currentRep) {
            currentRep = this.props.currentRep.siteName;
        }
        this.props.history.push(`/${currentRep}/more-info`);
    }

    render () {
        let products = [];
        if(this.props.count > 0) {
            products = this.props.mylist.map(product => {
                return <MyListProductCard key={product.productId} product={product} toggle={this.props.toggle} />
            });
        } else {
            products = <h5 className="text-center m-5">There are no products in MyList.</h5>;
        }
        return (
            <React.Fragment>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
                    <ModalHeader toggle={this.props.toggle} tag="h4">
                        MyList
                    </ModalHeader>
                    <ModalBody>
                        {products}
                    </ModalBody>
                    <ModalFooter>
                        <Button color={this.props.count === 0 ? 'secondary' : 'danger'} disabled={this.props.count === 0} onClick={this.toggleConfirm}>
                            <Icon icon="trash" /> Clear List
                        </Button>
                        <Button color="success" onClick={() => this.requestInfoClickHandler()}>
                            <Icon icon="info-circle" /> Request Information
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.confirm} toggle={this.toggleConfirm} style={{zIndex: 1051}}>
                    <ModalHeader toggle={this.toggleConfirm}>
                        Confirm
                    </ModalHeader>
                    <ModalBody>
                        Are you sure you want to remove all products from MyList?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.clearListClickHandler}>Yes, Clear List Now</Button>
                        <Button color="success" onClick={this.toggleConfirm}>No, Keep List</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
      mylist: state.myList.mylist,
      count: state.myList.count,
      currentRep: state.rep.rep
    }
  }

  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        clearMylist: actions.clearMylist
    }, dispatch);
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyListModal));