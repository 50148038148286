import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchCommonData, fetchOrgLanding } from '../Services/API';
import types from '../ActionTypes';
import actions from '../Actions';

function* getCommonData(action) {
    const response = yield call(fetchCommonData, action.payload.organizationId);
    yield put(actions.setCommonLists(response.data));
}

export function* commonDataSaga() {
    yield takeLatest(types.FETCH_COMMON_LIST, getCommonData);
}

function* getOrgLanding(action) {
    const response = yield call(fetchOrgLanding, action.payload.organizationId);
    yield put(actions.setLandingPage({ content: response.data }));
}

export function* orgLandingSaga() {
    yield takeLatest(types.GET_LANDING_PAGE, getOrgLanding);
}