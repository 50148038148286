import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../Store/Actions';
import {
    Collapse,
    Row,
    Col,
    Badge,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    UncontrolledTooltip,
} from 'reactstrap';
import Icon from '../Icon/icon';
import ManufacturerMenuItem from '../Header/ManufacturerMenuItem';
import CategoryMenuItem from '../Header/CategoryMenuItem';
import { ENV } from '../../hostConfig';
import { IMAGE_PATH } from '../Shared/Constants/Constants';

class Header extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
            rep: null,
            repSite: 'mds',
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    findRep(repName) {
        if (repName === 'mds') {
            this.setState({
                rep: null,
                repSite: 'mds',
            });
            this.props.setRep({ rep: null });
            return;
        }
        for (let i = 0; i < this.props.reps.length; i++) {
            const rep = this.props.reps[i];
            if (rep.siteName && rep.siteName.toLowerCase() === repName.toLowerCase()) {
                this.setState({
                    rep: rep,
                    repSite: rep.siteName,
                });
                this.props.setRep({ rep: rep });
                break;
            }
        }
    }

    componentDidMount() {
        this.props.fetchReps({
            organizationId: this.props.common.organization.organizationId,
        });
    }

    componentDidUpdate(prevProps) {
        if (
            (prevProps.loaded !== this.props.loaded && this.props.match.params.rep) ||
            (this.props.match.params.rep && this.props.match.params.rep !== prevProps.match.params.rep)
        ) {
            this.findRep(this.props.match.params.rep);
        }
    }

    render() {
        //console.log(this.props);
        console.log(IMAGE_PATH);
        let manufacturers = '';
        let categories = '';
        let brand = null;
        let color = null;
        manufacturers = this.props.manufacturers.map((manufacturer) => {
            return <ManufacturerMenuItem key={manufacturer.manufacturerId} manufacturer={manufacturer} repSite={this.state.repSite} />;
        });

        categories = this.props.categories.map((category) => {
            return category.tagTypeId === 2 ? <CategoryMenuItem key={category.tagId} category={category} repSite={this.state.repSite} /> : null;
        });

        if (this.state.rep) {
            brand = (
                <Row className="bg-white p-1">
                    <Col className="d-inline p-0">
                        <img src={`${IMAGE_PATH}${this.props.organization.organizationLogoUrl}`} height="64" alt={this.props.organization.organizationName} />
                        <img src={`${IMAGE_PATH}${this.state.rep.photoUrl}`} height="64" alt={this.state.rep.firstName} className="ms-1" />
                    </Col>
                    <Col className="ms-1 d-inline small p-0" id="headerrepcard">
                        <strong>{this.state.rep.fullName}</strong>
                        <br />
                        <Badge color="secondary">
                            <Icon icon="phone" color="secondary" />
                        </Badge>{' '}
                        {this.state.rep.phone}
                        <br />
                        <Badge color="secondary">
                            <Icon icon="envelope" color="secondary" />
                        </Badge>{' '}
                        {this.state.rep.email}
                    </Col>
                    <UncontrolledTooltip target="headerrepcard" placement="right">
                        Click to Remove Rep
                    </UncontrolledTooltip>
                </Row>
            );
        } else {
            brand = <img src={`${IMAGE_PATH}${this.props.organization.organizationLogoUrl}`} height="64" alt={this.props.organization.organizationName} />;
        }
        color = this.props.organization.organizationColor ? this.props.organization.organizationColor : '#3e3f3a';
        return (
            <Navbar expand="md" style={{ backgroundColor: `${color}` }} className={this.props.organization.useDarkFont ? 'navbar-light' : 'navbar-dark'}>
                <NavLink to="/mds" className="py-0 navbar-brand">
                    {brand}
                </NavLink>
                {ENV && <div className="nav-link ms-5 py-2 px-3 text-danger bg-white">{ENV}</div>}
                <NavbarToggler onClick={this.toggle} className="" />
                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="ms-auto" navbar>
                        <NavItem>
                            <NavLink to={`/${this.state.repSite}/dme/`} className="nav-link">
                                Browse Catalog
                            </NavLink>
                        </NavItem>
                        {this.props.manufacturers.length > 1 ? (
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret className="">
                                    Manufacturers
                                </DropdownToggle>
                                <DropdownMenu>{manufacturers}</DropdownMenu>
                            </UncontrolledDropdown>
                        ) : null}
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret className="">
                                Categories
                            </DropdownToggle>
                            <DropdownMenu>{categories}</DropdownMenu>
                        </UncontrolledDropdown>
                        <NavItem>
                            <NavLink to="/mds/rep" className="nav-link">
                                Sales Reps
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to={`/${this.state.repSite}/mylist`} className="nav-link">
                                MyList
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <a href="https://mds.orthotom.com" className="nav-link">
                                Login
                            </a>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        reps: state.reps.reps,
        loaded: state.reps.loaded,
        common: state.common,
        commonLoaded: state.common.loaded,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            fetchReps: actions.fetchReps,
            setRep: actions.setRep,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
