import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import {DateTime} from 'luxon';
import Moment from 'react-moment';
import { Container, Card, CardBody, Row, Col, Spinner, Button, Input, Modal, ModalBody } from 'reactstrap';
import ResponsiveLabelLg from '../Shared/Responsive/ResponsiveLabelLg';
import * as fn from '../Shared/Functions/FormFunctions';
import Icon from '../Icon/icon';
import actions from '../../Store/Actions';
import ApplyForm from '../ApplyForm/ApplyForm';
import ProductDetail from '../Product/productDetail';

class ViewQuote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            decline: false,
            accept: false,
            applyNow: false,
            note: '',
            declined: false,
            msg: null,
            viewProductId: null,
        };
        const hostname = window && window.location && window.location.hostname;
        let env = 'https://mds.orthotom.com';
        if (hostname.includes('localhost')) {
            env = 'http://localhost:3000';
        } else if (hostname.includes('staging')) {
            env = 'https://staging.orthotom.com';
        } else if (hostname.includes('beta')) {
            env = 'https://beta.orthotom.com';
        }
        this.mdsRedirectBase = env;
    }

    componentDidMount() {
        const params = this.props.location.pathname.split('/').reverse();
        this.props.getQuote({ supplierId: params[1], quoteId: params[0] });
    }

    componentDidUpdate(prevProps) {
        // get Quote
        if (!prevProps.viewQuote.loaded && this.props.viewQuote.loaded) {
            this.mdsRedirect = this.mdsRedirectBase + '/quote/' + this.props.viewQuote.quote.quoteGuid;
            if (this.props.viewQuote.quote && this.props.viewQuote.quote.prpSiteName && '' === this.props.location.pathname.split('/viewquote/')[0]) {
                this.props.history.push(`/${this.props.viewQuote.quote.prpSiteName}${this.props.location.pathname}`);
            }
            this.setState({
                applyData: this.props.viewQuote.quote.onboard,
            });
        }
        // save decline
        if (prevProps.result !== this.props.result && this.props.result.caller === 'DeclineQuote') {
            this.saveResult({ ...this.props.result });
        }
    }

    inputHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    toggleDecline = () => {
        this.setState({
            decline: !this.state.decline,
        });
    };

    toggleAccept = () => {
        this.setState({
            accept: !this.state.accept,
        });
    };

    changeResponse = () => {
        this.setState({
            decline: false,
            accept: false,
            notes: '',
        });
    };

    memberApply = () => {
        this.setState({
            applyData: {},
        });
    };

    applicationSaved = (applyData) => {
        this.setState({
            accept: false,
            applyData: applyData,
        });
    };

    viewProductDetails = (productId) => {
        this.setState({
            viewProductId: productId,
        });
    };

    declineHandler = () => {
        this.props.declineQuote({
            quoteResponse: {
                quoteId: this.props.viewQuote.quote.quoteId,
                accepted: false,
                responseNote: this.state.note,
            },
        });
        this.setState({ declined: true, response: null });
    };

    saveResult(res) {
        // error
        if (res.level > 0) {
            this.setState({
                msg: res.message,
                saving: false,
            });
            return;
        }

        // update (decline)
        if ('U' === res.actionType && res.returnId === this.props.viewQuote.quote.quoteId) {
            this.setState(
                {
                    msg: null,
                    decline: false,
                },
                () => {
                    this.props.getQuote({
                        supplierId: this.props.viewQuote.quote.supplierId,
                        quoteId: this.props.viewQuote.quote.quoteGuid,
                    });
                }
            );
            return;
        }
    }

    render() {
        //console.log('state', this.state)
        //console.log('props', this.props)
        const q = this.props.viewQuote.quote;
        return (
            <Container fluid>
                <Card>
                    <CardBody>
                        {!this.props.viewQuote.loaded && (
                            <div className="pt-2">
                                <Spinner />
                            </div>
                        )}

                        {this.props.viewQuote.loaded && this.props.viewQuote.notification.level > 0 && <div></div>}

                        {this.props.viewQuote.loaded && this.props.viewQuote.quote && (
                            <React.Fragment>
                                {!this.state.declined && !q.responseDate && !q.isExpired && <h2>{q.subject}</h2>}
                                <Row>
                                    <Col sm="24" md="16" className="p-2">
                                        <div>
                                            From {q.createdByName} on behalf of {q.supplierName}
                                        </div>
                                        <div>
                                            Expires on <Moment date={q.createdDate} format="MM/DD/YYYY" local="true" />
                                        </div>
                                        {q.quoteNote && <div>Note: {q.quoteNote}</div>}
                                    </Col>
                                    {this.state.declined || (!!q.responseDate && q.accepted === false) ? (
                                        <Col sm="24" md="8" className="text-end p-2">
                                            <h5 className="text-danger">
                                                Declined - <Moment date={q.responseDate} format="MM/DD/YYYY" local="true" />
                                            </h5>
                                        </Col>
                                    ) : q.accepted ? (
                                        <Col sm="24" md="8" className="text-end p-2">
                                            <h5 className="text-info">
                                                Accepted - <Moment date={q.responseDate} format="MM/DD/YYYY" local="true" />
                                            </h5>
                                        </Col>
                                    ) : q.isExpired ? (
                                        <Col s="24" md="8" className="text-end p-2">
                                            <h5 className="text-danger">Expired</h5>
                                        </Col>
                                    ) : (
                                        <Col s="24" md="8" className="text-end p-2">
                                            {this.state.applyData ? (
                                                <Button color="info" outline onClick={this.toggleAccept}>
                                                    <Icon icon="handshake" type="far" /> Edit Application
                                                </Button>
                                            ) : (
                                                <Button color="success" outline onClick={this.toggleAccept}>
                                                    <Icon icon="handshake" type="far" /> Accept
                                                </Button>
                                            )}
                                            <Button color="danger" outline onClick={this.toggleDecline} className="ms-2">
                                                <Icon icon="times" /> Decline
                                            </Button>
                                        </Col>
                                    )}
                                </Row>

                                <Row className="bg-primary border-bottom d-none d-lg-flex text-white font-weight-bold m-0 mt-3">
                                    <Col lg="12" className="p-2">
                                        Product
                                    </Col>
                                    <Col lg="6" className="p-2 border-start border-light">
                                        Billing Codes
                                    </Col>
                                    {q.showExtraFinancials && (
                                        <React.Fragment>
                                            <Col lg="2" className="p-2 border-start border-light">
                                                List
                                            </Col>
                                            <Col lg="2" className="p-2 border-start border-light">
                                                Discount
                                            </Col>
                                        </React.Fragment>
                                    )}
                                    <Col lg={q.showExtraFinancials ? '2' : '6'} className="p-2 border-start border-light">
                                        Your Price
                                    </Col>
                                </Row>
                                <Row className="border-bottom d-flex d-lg-none m-0"></Row>

                                {q.details.map((m) => (
                                    <Row
                                        key={`detail_${m.productId}`}
                                        className="border-bottom border-right m-0 hoverTint pointer"
                                        onClick={() => this.viewProductDetails(m.productId)}
                                    >
                                        <Col md="24" lg="12" className="p-2 border-start">
                                            <ResponsiveLabelLg label="Product" size="lg" />
                                            {m.productNameTlc}
                                            {m.quantity > 1 && (
                                                <span className="ms-1">
                                                    {m.quantity} {m.quantityLabel}
                                                </span>
                                            )}
                                        </Col>
                                        <Col md="24" lg="6" className="p-2 border-start">
                                            <ResponsiveLabelLg label="Billing Codes" />
                                            {m.billingCodes && m.billingCodes.join(', ')}
                                        </Col>
                                        {q.showExtraFinancials && (
                                            <React.Fragment>
                                                <Col md="24" lg="2" className="p-2 border-start">
                                                    <ResponsiveLabelLg label="List" />
                                                    {fn.Money(m.list)}
                                                </Col>
                                                <Col md="24" lg="2" className="p-2 border-start">
                                                    <ResponsiveLabelLg label="Discount" />
                                                    {m.discount}%
                                                </Col>
                                            </React.Fragment>
                                        )}
                                        <Col md="24" lg={q.showExtraFinancials ? '2' : '6'} className="p-2 border-start">
                                            <ResponsiveLabelLg label="Price" />
                                            {fn.Money(m.quotedPrice)} {m.quantityLabel}
                                        </Col>
                                    </Row>
                                ))}
                            </React.Fragment>
                        )}
                    </CardBody>
                </Card>

                <Modal isOpen={this.state.accept} toggle={this.changeResponse} size={this.state.applyData ? 'xl' : 'md'}>
                    <ModalBody className="p-4 text-center">
                        {this.state.applyData ? (
                            <ApplyForm update={this.applicationSaved} applyData={this.applyData} mdsRedirect={this.mdsRedirect} quote={q} />
                        ) : (
                            <React.Fragment>
                                <h5 className="mb-3">Are you already a member of The Orthopedic Marketplace?</h5>
                                <a
                                    className="btn btn-outline-success"
                                    href={this.mdsRedirect}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    onClick={this.changeResponse}
                                >
                                    Yes, Sign In
                                </a>
                                <Button color="info" outline onClick={this.memberApply} className="ms-4">
                                    No, Apply Now
                                </Button>
                            </React.Fragment>
                        )}
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.decline} toggle={this.changeResponse} size="md">
                    <ModalBody>
                        <h5 className="mb-3">Would you like to request an alteration or provide a reason?</h5>
                        <Input type="textarea" name="note" value={this.state.note} onChange={this.inputHandler} maxLength="2000" />
                        <Button color="danger" outline onClick={this.declineHandler} className="mt-3">
                            Decline Now
                        </Button>
                    </ModalBody>
                </Modal>

                {this.state.viewProductId && (
                    <Modal isOpen={true} toggle={() => this.viewProductDetails(null)} size="xl">
                        <ModalBody>
                            <ProductDetail productId={this.state.viewProductId} orgId={q.supplierId} />
                        </ModalBody>
                    </Modal>
                )}
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    viewQuote: state.viewQuote,
    result: state.genericResult,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getQuote: actions.getQuote,
            declineQuote: actions.declineQuote,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewQuote);
