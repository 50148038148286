import React, { Component } from 'react';
import {
    Row,
    Col,
    Button
} from 'reactstrap';
import ProductCard from '../Product/productCard';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import Icon from '../Icon/icon';

class Carousel extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            display: 'd-block',
            caret: 'caret-up'
        };
    }

    toggle() {
        const display = this.state.display === 'd-none' ? 'd-block' : 'd-none';
        const caret = this.state.display === 'd-none' ? 'caret-up' : 'caret-down';
        this.setState(
            { 
                display: display,
                caret: caret
            }
        );
    }
    handleOnDragStart = (e) => {
        e.preventDefault();
    }

    render() {
        const responsive = {
            0: {
                items: 1
            },
            610: {
                items: 2
            },
            920: {
                items: 3
            },
            1240: {
                items: 4
            },
            1560: {
                items: 5
            },
            1880: {
                items: 6
            },
            2200: {
                items: 7
            },
            2510: {
                items: 8
            },
            2820: {
                items: 9
            }
        };
        let carouselItems = [];
        for (let x = 0; x < this.props.products.length; x++) {
            const product = this.props.products[x];
            for (let i = 0; i < product.catalogTags.length; i++) {
                const element = product.catalogTags[i];
                if(element.tagId === this.props.tagId){
                    carouselItems.push(<ProductCard onDragStart={this.handleOnDragStart} product={product} key={product.productId} />);
                }
            }
        }
        
        return (
            <React.Fragment>
                <Row>
                    <Col className="border-bottom mb-2">
                        <h2 className="text-dark">
                            <Button color="light" onClick={this.toggle} size="sm">
                                <Icon icon={this.state.caret} size="lg" />
                            </Button> {this.props.header} Products
                        </h2>
                    </Col>
                </Row>
                <Row className={this.state.display}>
                    <Col>
                        <AliceCarousel mouseDragEnabled responsive={responsive} items={carouselItems} autoPlay duration={3000} />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default Carousel;