import instance from '../Axios';

export const fetchOrganizationId = (prpUrl) => {
    return instance.get(`prp/organization/${prpUrl}`);
};

export const fetchCommonData = (organizationId) => {
    return instance.get(`prp/${organizationId}`);
};

export const fetchOrgLanding = (organizationId) => {
    return instance.get(`prp/${organizationId}/landing`);
};

export const fetchProducts = (params) => {
    return instance.post(`prp/supplierCatalog`, params);
};

export const fetchProduct = (productId, organizationId) => {
    return instance.get(`prp/product/${productId}/${organizationId}`);
};

export const getSentList = (params) => {
    return instance.post(`prp/sentList`, params);
};

export const fetchReps = (organizationId) => {
    return instance.get(`prp/reps/${organizationId}`);
};

export const saveContact = (contact) => {
    return instance.post(`prp/lead`, contact);
};

export const fetchIsrs = (organizationId) => {
    return instance.get(`prp/isrs/${organizationId}`);
};

export const apiGetQuote = (supplierId, quoteId) => {
    return instance.get(`prp/viewQuote/${supplierId}/${quoteId}`);
};

export const apiDeclineQuote = (quoteResponse) => {
    return instance.post(`prp/declineQuote`, quoteResponse);
};

export const apiSaveApplication = (applyData) => {
    return instance.post(`prp/stageApplication`, applyData);
};
