import React from 'react';
import { Row, Col, Card, CardFooter, CardTitle, CardImg, CardBody, Badge } from 'reactstrap';
import ProductToggle from '../MyList/ProductToggle';
import ProductInfoButton from '../Product/productInfoButton';
import { IMAGE_PATH } from '../Shared/Constants/Constants';

const myListProductCard = (props) => {
    return (
        <Card key={props.product.productId} className="mb-2 shadow-sm">
            <CardBody>
                <Row>
                    <Col xs="8">
                        <CardImg
                            width="100%"
                            src={`${IMAGE_PATH}${props.product.mainPhotoUrl}?anchor=middlecenter&mode=pad&format=jpg&height=400&width=400&quality=80`}
                        />
                    </Col>
                    <Col xs="16">
                        <CardTitle tag="h5">{props.product.productNameTlc}</CardTitle>
                        <h6>by {props.product.manufacturerName}</h6>
                        {props.showDescription && (
                            <Row>
                                <Col>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: props.product.productDescription,
                                        }}
                                    />
                                </Col>
                            </Row>
                        )}
                        {props.product.productBillingCodes && (
                            <React.Fragment>
                                <Row className="border-bottom mx-2">
                                    <Col>Billing Codes</Col>
                                </Row>
                                {props.product.productBillingCodes.map((codes) => {
                                    return (
                                        <Row key={codes.productBillingCodeId} className="mx-2 pt-1">
                                            <Col>
                                                {codes.billingCode} <Badge color="secondary">{codes.billingTypeCode}</Badge>
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </React.Fragment>
                        )}
                    </Col>
                </Row>
            </CardBody>
            <CardFooter>
                <Row>
                    <Col className="text-end">
                        <ProductToggle product={props.product} className="me-2" />
                        <ProductInfoButton productId={props.product.productId} productName={props.product.productName} toggle={props.toggle} />
                    </Col>
                </Row>
            </CardFooter>
        </Card>
    );
};

export default myListProductCard;
