import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchReps } from '../Services/API';
import types from '../ActionTypes';
import actions from '../Actions';

function* getReps(action) {
    const response = yield call(fetchReps, action.payload.organizationId);
    yield put(actions.setReps(response.data));
}

export function* repsSaga() {
    yield takeLatest(types.FETCH_REPS, getReps);
}