import types from '../../Store/ActionTypes';

const initialState = {
    content: null,
    complete: false
};

const LandingPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_LANDING_PAGE:
            return {
                ...state,
                content: action.payload.content,
                complete: true
            };
        
        case types.GET_LANDING_PAGE:
            return initialState;

        default:
            return state;
    }
};

export default LandingPageReducer;
