import { createTypes } from 'redux-action-creator';

const types = createTypes([
    'FETCH_ORGANIZATION_ID',
    'SET_ORGANIZATION_ID',
    'FETCH_COMMON_LIST',
    'SET_COMMON_LIST',
    'FETCH_REPS',
    'SET_REPS',
    'SET_REP',
    'FETCH_ISRS',
    'SET_ISRS',
    'FETCH_PRODUCTS',
    'SET_PRODUCTS',
    'FETCH_PRODUCT',
    'SET_PRODUCT',
    'SET_TO_MYLIST',
    'REMOVE_FROM_MYLIST',
    'CLEAR_MYLIST',
    'SAVE_CONTACT',
    'SAVE_CONTACT_STARTED',
    'SAVE_CONTACT_COMPLETED',
    'GET_SENT_LIST',
    'SET_SENT_LIST',
    'GET_QUOTE',
    'SET_QUOTE',
    'DECLINE_QUOTE',
    'DECLINE_QUOTE_COMPLETE',
    'SAVE_APPLICATION',
    'SAVE_APPLICATION_COMPLETE',
    'GET_LANDING_PAGE',
    'SET_LANDING_PAGE'
]);

export default types;