import React from 'react';
import { Row, Col, Card, CardSubtitle, CardTitle, CardBody } from 'reactstrap';
import Icon from '../Icon/icon';
import { IMAGE_PATH } from '../Shared/Constants/Constants';

const myListRepCard = (props) => {
    console.log(props);
    return (
        <Card className="shadow-sm mb-3">
            <CardBody>
                <Row>
                    <Col xs="6">
                        <img
                            width="100%"
                            alt={props.currentRep.fullName}
                            src={`${IMAGE_PATH}${props.currentRep.photoUrl}?anchor=middlecenter&mode=crop&format=jpg&height=100&width=100&quality=80`}
                            style={{ maxWidth: 100 }}
                            className="rounded"
                        />
                    </Col>
                    <Col xs={{ size: 16, offset: 2 }}>
                        <CardTitle tag="h4" className="mb-1">
                            {props.currentRep.fullName}
                        </CardTitle>
                        <CardSubtitle className="ms-1 text-muted">{props.currentRep.jobTitle}</CardSubtitle>
                        <Row className="mb-1">
                            <Col>
                                <span className="text-secondary me-2">
                                    <Icon icon="map-marker-alt" size="lg" />
                                </span>
                                {props.currentRep.salesTerritory}
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col>
                                <span className="text-secondary me-2">
                                    <Icon icon="phone" size="lg" />
                                </span>
                                <a href={`tel:${props.currentRep.phone}`}>{props.currentRep.phone}</a>
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col>
                                <span className="text-secondary me-2">
                                    <Icon icon="envelope" size="lg" />
                                </span>
                                <a href={`mailto:${props.currentRep.email}`}>{props.currentRep.email}</a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default myListRepCard;
