import React from 'react';

import { NavLink } from 'reactstrap';

const navTabLink = (props) => {
    return (
        <NavLink
            className={props.className}
            onClick={() => props.clicked()}
        >
            {props.children}
        </NavLink>
    )
}

export default navTabLink;