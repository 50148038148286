import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../Store/Actions';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import {
    Row,
    Col,
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    CardImg,
    Spinner,
    UncontrolledTooltip,
    TabContent,
    TabPane,
    Nav,
    NavItem,
} from 'reactstrap';
import Icon from '../Icon/icon';
import Toolbar from '../Toolbar/Toolbar';
import TabNavLink from '../Tabs/TabNavLink';
import classnames from 'classnames';
import ProductToggle from '../MyList/ProductToggle';
import { IMAGE_PATH, FILE_PATH } from '../Shared/Constants/Constants';

// /:rep/dme/detail/:productId/:productName
class ProductDetail extends Component {
    constructor(props) {
        super(props);
        this.isModal = this.props.productId > 0 && this.props.orgId > 0;
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: 'variations',
        };
    }

    handleOnDragStart = (e) => e.preventDefault();

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    fetchProduct = () => {
        if (this.isModal) {
            this.props.fetchProduct({
                productId: this.props.productId,
                organizationId: this.props.orgId,
            });
        } else {
            this.props.fetchProduct({
                productId: this.props.match.params.productId,
                organizationId: this.props.common.organization.organizationId,
            });
        }
    };

    componentDidMount() {
        this.fetchProduct();
    }

    componentDidUpdate(PrevProps) {
        if (!this.isModal && PrevProps.match.params.productId !== this.props.match.params.productId) {
            this.fetchProduct();
        }
    }

    createMarkup(html) {
        return { __html: html };
    }

    render() {
        let BreadcrumbItems = <Spinner color="secondary" size="sm" />;
        let productCard = (
            <div className="text-center mt-5">
                <Spinner color="primary" style={{ width: '6rem', height: '6rem' }} />
            </div>
        );
        let billingCodes = null;
        let variants = null;
        let files = false;
        let youtube = false;
        let additionalTabs = [];
        let additionalContent = [];
        let multipleImages = false;
        if (this.props.loaded && this.props.product !== null) {
            if (!this.isModal) {
                BreadcrumbItems = [];
                BreadcrumbItems.push(
                    <BreadcrumbItem key={`man${this.props.product.manufacturerId}`}>
                        <NavLink
                            to={`/${this.props.currentRep ? this.props.currentRep.siteName : 'mds'}/dme/manufacturers/${this.props.product.manufacturerId}/${
                                this.props.product.manufacturerName
                            }`}
                        >
                            {this.props.product.manufacturerName}
                        </NavLink>
                    </BreadcrumbItem>
                );
                for (let i = 0; i < this.props.product.categoryTags.length; i++) {
                    const category = this.props.product.categoryTags[i];
                    BreadcrumbItems.push(
                        <BreadcrumbItem key={`cat${category.tagId}-${i}`}>
                            <NavLink
                                to={`/${this.props.currentRep ? this.props.currentRep.siteName : 'mds'}/dme/categories/${category.tagId}/${category.tagName}`}
                            >
                                {category.tagName}
                            </NavLink>
                        </BreadcrumbItem>
                    );
                }
                BreadcrumbItems.push(<BreadcrumbItem key={`prod${this.props.product.productId}`}>{this.props.product.productName}</BreadcrumbItem>);
            }
            if (this.props.product.productBillingCodes && this.props.product.productBillingCodes.length > 0) {
                billingCodes = this.props.product.productBillingCodes.map((code) => {
                    return (
                        <Row key={code.productBillingCodeId}>
                            <Col sm="24" className="border-bottom mb-2">
                                <h5>
                                    {code.billingCode}{' '}
                                    <Badge color="dark" id={`bc-${code.billingCodeId}`}>
                                        {code.billingTypeCode}
                                    </Badge>
                                </h5>
                                <UncontrolledTooltip target={`bc-${code.billingCodeId}`}>{code.billingTypeName}</UncontrolledTooltip>
                            </Col>
                        </Row>
                    );
                });
            } else {
                billingCodes = (
                    <Row>
                        <Col md="24">N/A</Col>
                    </Row>
                );
            }
            let count = 0;
            variants = this.props.product.productVariations.map((variant) => {
                count++;
                return (
                    <Row className="border-bottom p-1" key={`var${variant.itemNumber}${count}`}>
                        <Col>{variant.itemNumber ? variant.itemNumber : 'N/A'}</Col>
                        <Col>{variant.sizeName ? variant.sizeName : 'N/A'}</Col>
                        <Col>{variant.sideName ? variant.sideName : 'N/A'}</Col>
                    </Row>
                );
            });

            //Check for pdfs (files) and youtube videos
            for (let i = 0; i < this.props.product.productMedia.length; i++) {
                const media = this.props.product.productMedia[i];
                if (media.contentType === 'application/pdf') {
                    files = true;
                } else if (media.contentType === 'video/youtube') {
                    youtube = true;
                } else if (media.contentType === 'image/png' || media.contentType === 'image/jpeg') {
                    multipleImages = true;
                } else if (media.contentType === 'text/html' && media.content) {
                    additionalTabs.push(
                        <NavItem key={`bloba${media.mediaId}`}>
                            <TabNavLink
                                className={classnames({
                                    active: this.state.activeTab === `${media.title}`,
                                })}
                                clicked={() => {
                                    this.toggle(`${media.title}`);
                                }}
                            >
                                {media.title}
                            </TabNavLink>
                        </NavItem>
                    );
                    additionalContent.push(
                        <TabPane tabId={media.title} key={`blobb${media.mediaId}`}>
                            <div dangerouslySetInnerHTML={this.createMarkup(media.content)} className="p-2" />
                        </TabPane>
                    );
                }
            }
            let fileList = null;

            if (files) {
                fileList = this.props.product.productMedia.map((media) => {
                    return media.contentType === 'application/pdf' ? (
                        <Row className="border-bottom p-1" key={`file${media.mediaId}`}>
                            <Col>
                                <a href={`${FILE_PATH}${media.content}`} target="_blank" rel="noopener noreferrer">
                                    <Icon icon="file-pdf" className="me-2" />
                                    {media.title}
                                </a>
                            </Col>
                        </Row>
                    ) : null;
                });
            }

            let youtubeList = null;
            if (youtube) {
                youtubeList = this.props.product.productMedia
                    .filter((f) => f.contentType === 'video/youtube')
                    .map((media) => {
                        return (
                            <Row className="border-bottom py-1 text-center" key={`yt${media.mediaId}`}>
                                <Col>
                                    <iframe
                                        title={media.title}
                                        width="560"
                                        height="315"
                                        src={`https://www.youtube.com/embed/${media.content}`}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    />
                                </Col>
                            </Row>
                        );
                    });
            }
            let productImageContent = null;
            let mainimage = this.props.product.mainPhotoUrl ? this.props.product.mainPhotoUrl : '/images/no_image.jpg';
            if (multipleImages) {
                productImageContent = [];
                productImageContent.push(
                    <CardImg key={`mainimage`} width="100%" src={`${IMAGE_PATH}${mainimage}?format=jpg&height=350&quality=80`} className="border" />
                );
                for (let i = 0; i < this.props.product.productMedia.length; i++) {
                    const media = this.props.product.productMedia[i];
                    if (media.contentType === 'image/png' || media.contentType === 'image/jpeg') {
                        productImageContent.push(
                            <CardImg
                                key={`image${media.mediaId}`}
                                width="100%"
                                src={`${IMAGE_PATH}${media.content}?format=jpg&height=350&quality=80`}
                                className="border"
                            />
                        );
                    }
                }
            } else {
                productImageContent = <CardImg width="100%" src={`${IMAGE_PATH}${mainimage}?format=jpg&height=350&quality=80`} className="border" />;
            }
            productCard = (
                <Card>
                    <CardHeader>
                        <Row>
                            <Col sm="12">
                                <CardTitle tag="h3">
                                    {this.props.product.manufacturerLogo && (
                                        <img
                                            src={`${IMAGE_PATH}${this.props.product.manufacturerLogo}?anchor=middlecenter&mode=max&format=jpg&height=40&width=40`}
                                            className="me-1"
                                            alt={this.props.product.manufacturerName}
                                        />
                                    )}{' '}
                                    {this.props.product.productNameTlc}
                                </CardTitle>
                            </Col>
                            <Col sm="12" className="text-end">
                                {!this.isModal && <ProductToggle product={this.props.product} />}
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col sm="8">
                                <Card className="shadow-sm mb-3">
                                    <CardBody>
                                        {multipleImages ? (
                                            <AliceCarousel mouseDragEnabled>{productImageContent}</AliceCarousel>
                                        ) : (
                                            <span>{productImageContent}</span>
                                        )}
                                    </CardBody>
                                </Card>
                                <Card className="shadow-sm mb-3">
                                    <CardHeader>
                                        <CardTitle tag="h5">Suggested Insurance Billing Codes</CardTitle>
                                    </CardHeader>
                                    <CardBody>{billingCodes}</CardBody>
                                </Card>
                            </Col>
                            <Col sm="16">
                                <Card className="shadow-sm mb-3">
                                    <CardHeader>
                                        <CardTitle tag="h4">Product Description</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <p dangerouslySetInnerHTML={this.createMarkup(this.props.product.productDescription)} />
                                    </CardBody>
                                </Card>
                                <Card className="shadow-sm mb-3">
                                    <CardHeader>
                                        <CardTitle tag="h5">Available Product Information</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Nav tabs>
                                            <NavItem>
                                                <TabNavLink
                                                    className={classnames({
                                                        active: this.state.activeTab === 'variations',
                                                    })}
                                                    clicked={() => {
                                                        this.toggle('variations');
                                                    }}
                                                >
                                                    Variants
                                                </TabNavLink>
                                            </NavItem>
                                            {files ? (
                                                <NavItem>
                                                    <TabNavLink
                                                        className={classnames({
                                                            active: this.state.activeTab === 'files',
                                                        })}
                                                        clicked={() => {
                                                            this.toggle('files');
                                                        }}
                                                    >
                                                        Forms
                                                    </TabNavLink>
                                                </NavItem>
                                            ) : null}
                                            {youtube ? (
                                                <NavItem>
                                                    <TabNavLink
                                                        className={classnames({
                                                            active: this.state.activeTab === 'youtube',
                                                        })}
                                                        clicked={() => {
                                                            this.toggle('youtube');
                                                        }}
                                                    >
                                                        Videos
                                                    </TabNavLink>
                                                </NavItem>
                                            ) : null}
                                            {additionalTabs}
                                        </Nav>
                                        <TabContent activeTab={this.state.activeTab}>
                                            <TabPane tabId="variations">
                                                <Row className="border-bottom p-2 mt-2 font-weight-bold bg-primary text-white">
                                                    <Col>Item Number</Col>
                                                    <Col>Size</Col>
                                                    <Col>Side</Col>
                                                </Row>
                                                {variants}
                                            </TabPane>
                                            {files ? <TabPane tabId="files">{fileList}</TabPane> : null}
                                            {youtube ? <TabPane tabId="youtube">{youtubeList}</TabPane> : null}
                                            {additionalContent}
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            );
        }
        return (
            <React.Fragment>
                {!this.isModal && (
                    <React.Fragment>
                        <Toolbar showBrowse />
                        <Breadcrumb>{BreadcrumbItems}</Breadcrumb>
                    </React.Fragment>
                )}
                {productCard}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        product: state.product.product,
        loaded: state.product.loaded,
        common: state.common,
        currentRep: state.rep.rep,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            fetchProduct: actions.fetchProduct,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
