import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchProduct } from '../Services/API';
import types from '../ActionTypes';
import actions from '../Actions';

function* getProduct(action) {
    const response = yield call(fetchProduct, action.payload.productId, action.payload.organizationId);
    yield put(actions.setProduct(response.data));
}

export function* productSaga() {
    yield takeLatest(types.FETCH_PRODUCT, getProduct);
}