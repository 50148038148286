import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import organizationIdReducer from './OrganizationIdReducer';
import commonListReducer from './CommonListReducer';
import productsReducer from './ProductsReducer';
import productReducer from './ProductReducer';
import repsReducer from './RepsReducer';
import repReducer from './RepReducer';
import mylistReducer from './MylistReducer';
import sentListReducer from './SentListReducer';
import saveContactReducer from './SaveContactReducer';
import isrsReducer from './IsrsReducer';
import quoteReducer from './QuoteReducer';
import genericResultReducer from './GenericResultReducer';
import LandingPageReducer from './LandingPageReducer';

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    organizationId: organizationIdReducer,
    common: commonListReducer,
    products: productsReducer,
    product: productReducer,
    reps: repsReducer,
    rep: repReducer,
    myList: mylistReducer,
    sentList: sentListReducer,
    saveContact: saveContactReducer,
    isrs: isrsReducer,
    viewQuote: quoteReducer,
    genericResult: genericResultReducer,
    landingPage: LandingPageReducer
});

export default rootReducer;