import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    Container,
    Row,
    Col
} from 'reactstrap';

import Toolbar from '../Toolbar/Toolbar';
import Carousel from '../Carousel/Carousel';

class Home extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { collapse: false };
    }

    toggle() {
        this.setState(state => ({ collapse: !state.collapse }));
    }

    render () {
        let bestProds = null;
        let featuredProds = null;
        if(this.props.loaded && this.props.products.length > 0){
            bestProds = <Carousel products={this.props.products} tagId={77} header="Best Selling" />
            featuredProds = <Carousel products={this.props.products} tagId={78} header="Featured" />
        }
        return (
            <Container fluid>
                <Toolbar showBrowse />
                <Row>
                    <Col>{bestProds}</Col>
                </Row>
                <Row>
                    <Col>{featuredProds}</Col>
                </Row>
            </Container> 
        );
    }
}
const mapStateToProps = state => {
    return {
      products: state.products.products,
      loaded: state.products.loaded
    }
  }
  
  export default connect(mapStateToProps)(Home);