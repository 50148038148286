import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { compose, createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { initSagas } from './Store/initSagas';
import rootReducer from './Store/Reducers/RootReducer';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './Views/Styles/bootstrap.css';
import './Views/Styles/kendo-all.css';

import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faSearch,
    faShoppingCart,
    faPrescriptionBottle,
    faExclamationCircle,
    faCaretDown,
    faCaretUp,
    faList,
    faPlusSquare,
    faUsers,
    faPhone,
    faEnvelope,
    faGlobe,
    faMapMarkerAlt,
    faUser,
    faStar,
    faPlusCircle,
    faFilePdf,
    faBookOpen,
    faTimes,
    faMinusCircle,
    faInfoCircle,
    faTrash,
    faHome,
    faBars,
    faBuilding,
    faFire,
} from '@fortawesome/free-solid-svg-icons';

import { faHandshake as farHandshake, faSave as farSave } from '@fortawesome/free-regular-svg-icons';

library.add(
    faSearch,
    faShoppingCart,
    faPrescriptionBottle,
    faExclamationCircle,
    faCaretDown,
    faCaretUp,
    faList,
    faPlusSquare,
    faUsers,
    faPhone,
    faEnvelope,
    faGlobe,
    faMapMarkerAlt,
    faUser,
    faStar,
    faPlusCircle,
    faFilePdf,
    faBookOpen,
    faTimes,
    faMinusCircle,
    faInfoCircle,
    faTrash,
    faHome,
    faBars,
    faBuilding,
    farHandshake,
    farSave,
    faFire
);

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();
const store = createStore(rootReducer(history), compose(applyMiddleware(routerMiddleware(history), sagaMiddleware)));
initSagas(sagaMiddleware);

ReactDOM.render(
    <Provider store={store}>
        <App history={history} />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
