import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../Store/Actions';
import {Row,Col,Card,CardHeader,CardTitle,CardBody,Spinner} from 'reactstrap';
import SentListProductCard from '../Product/SentListProductCard';
import Toolbar from '../Toolbar/Toolbar';
import Icon from '../Icon/icon';
import { Pager } from '@progress/kendo-react-data-tools';

class SentList extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            listId: 0,
            skip: 0,
            take: 12,
        };
    }

    componentDidMount() {        
        this.props.fetchProducts({
            params: {
                supplierIds: [this.props.common.organization.organizationId],
                manufacturerIds: [],
                categoryTagIds: [],
                productTagIds: [],
                search: '',
                skip: this.state.skip,
                take: this.state.take
            }
        })
        this.setState({
            listId: parseInt(this.props.match.params.listId)
        },() => {
            this.getList();
        })
    }

    getList = () => {
        this.props.getSentList({
            params: {
                productListId: this.state.listId,
                skip: this.state.skip,
                take: this.state.take
            }
        });
    } 

    render () {
        return (
            <React.Fragment>
            <Toolbar />
            <Card>
                <CardHeader>
                    <CardTitle tag="h4">
                        <Icon icon="list" /> {this.props.list.loaded && this.props.list.name}
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            {this.props.list.loaded
                            ? this.props.list.products.map(m => <SentListProductCard key={m.productId} product={m} showDescription />)
                            : <Spinner/>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Pager
                                skip={this.state.skip}
                                take={this.state.take}
                                buttonCount={3}
                                total={this.props.list.count || 0}
                                pageSizes={[12, 24, 48, 96]}
                                previousNext={true}
                                onPageChange={(e) => this.setState({skip: e.skip, take: e.take}, () => {this.getList();})}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        common: state.common,
        list: state.sentList,
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        fetchProducts: actions.fetchProducts,
        getSentList: actions.getSentList,
    }, dispatch);
}
export default connect(mapStateToProps,mapDispatchToProps)(SentList);
