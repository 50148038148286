import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchIsrs } from '../Services/API';
import types from '../ActionTypes';
import actions from '../Actions';

function* getIsrs(action) {
    const response = yield call(fetchIsrs, action.payload.organizationId);
    yield put(actions.setIsrs(response.data));
}

export function* isrsSaga() {
    yield takeLatest(types.FETCH_ISRS, getIsrs);
}