import React from 'react';
import DropDownItem from '../UI/DropDownItem';

const categoryMenuItem = (props) => {
    return (
        <DropDownItem key={`cat${props.category.tagId}`} to={`/${props.repSite}/dme/categories/${props.category.tagId}/${props.category.tagName.replace(/\s+/g, '-').toLowerCase()}`}>
                {props.category.tagName}
        </DropDownItem>
    );
}

export default categoryMenuItem;