import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../Store/Actions';
import { Col, Row, Button, Badge, Spinner, UncontrolledTooltip } from 'reactstrap';
import Icon from '../Icon/icon';
import MyListModal from './MyListModal';

class MyList extends Component {
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.state = {
            mylistCount: 0,
            modal: false,
        };
    }

    toggleModal() {
        this.setState((state) => ({ modal: !state.modal }));
    }

    componentDidMount() {
        let mylist = localStorage.getItem('mylist');
        if (mylist) {
            this.props.setToMylist({ mylist: JSON.parse(mylist) });
        }
    }

    render() {
        let myListButton = (
            <div className="text-end">
                <Spinner color="primary" size="sm" />
            </div>
        );
        if (this.props.loaded) {
            myListButton = (
                <React.Fragment>
                    <Button color="light" size="lg" onClick={this.toggleModal} id="myListButton">
                        <Icon icon="list" size="2x" />
                        <h4 className="d-inline ms-2">
                            <Badge color="light">{this.props.count}</Badge>
                        </h4>
                    </Button>
                    <UncontrolledTooltip placement="left" target="myListButton">
                        {this.props.count > 0 ? 'View MyList' : 'Add Items to MyList, then click here to view them.'}
                    </UncontrolledTooltip>
                </React.Fragment>
            );
        }
        return (
            <Row>
                <Col className="text-end">
                    {myListButton}
                    <MyListModal isOpen={this.state.modal} toggle={this.toggleModal} />
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        products: state.products.products,
        loaded: state.products.loaded,
        count: state.myList.count,
        mylist: state.myList.mylist,
        organization: state.common.organization,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            fetchProducts: actions.fetchProducts,
            setToMylist: actions.setToMylist,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyList);
