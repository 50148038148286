import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../Store/Actions';
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Spinner
} from 'reactstrap';
import Icon from '../Icon/icon';
import RepCard from './RepCard';
import IsrCard from './IsrCard';
import Toolbar from '../Toolbar/Toolbar';
import classnames from 'classnames';
//import RepMap from './RepMap';

class RepListing extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: 'reps'
        }
    }

    componentDidMount() {
        this.props.fetchIsrs({ organizationId: this.props.common.organization.organizationId });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        let RepListing = '';
        //let repMap = null;
        let IsrListing = <Spinner/>;
        let isrTab = null;
        let isrPane = null;

        RepListing = this.props.reps.map((rep, r) => {
            return <RepCard key={`rep-${rep.userId}-${r}`} rep={rep} />;
        });
        
        if (this.props.isrsLoaded){
            if(this.props.isrs.length > 0){
                IsrListing = this.props.isrs.map(isr => {
                    return <IsrCard key={isr.organizationId} isr={isr} />
                });
                isrTab = 
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === 'isrs' })}
                            onClick={() => { this.toggle('isrs'); }}
                        >Independent Sales Representatives</NavLink>
                    </NavItem>;
                isrPane = 
                    <TabPane tabId="isrs">
                        {IsrListing}
                    </TabPane>
            }
            
            //repMap = <RepMap reps={this.props.reps} isrs={this.props.isrs} organization={this.props.common.organization} />;
        }

        return (
            <React.Fragment>
                <Toolbar showBrowse />
                <Card>
                    <CardHeader>
                        <CardTitle tag="h4">
                            <Icon icon="users" size="lg" /> Sales Team
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Nav tabs className="mb-3">
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === 'reps' })}
                                    onClick={() => { this.toggle('reps'); }}
                                >Representatives</NavLink>
                            </NavItem>
                            {isrTab}
                             {/*
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === 'map' })}
                                    onClick={() => { this.toggle('map'); }}
                                >Map</NavLink>
                            </NavItem>
                             */}
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="reps">
                                {RepListing}
                            </TabPane>
                            {isrPane}
                            {/* 
                            <TabPane tabId="map">
                                {repMap}
                            </TabPane>
                            */}
                        </TabContent>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        reps: state.reps.reps,
        isrs: state.isrs.isrs,
        isrsLoaded: state.isrs.loaded,
        loaded: state.reps.loaded,
        common: state.common
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        fetchIsrs: actions.fetchIsrs,
        setIsrs: actions.setIsrs
    }, dispatch);
}

//export default connect(mapStateToProps)(RepListing);
export default connect(mapStateToProps, mapDispatchToProps)(RepListing);