import React from 'react';
import DropDownItem from '../UI/DropDownItem';

const manufacturerMenuItem = (props) => {
    return (
        <DropDownItem key={`man${props.manufacturer.manufacturerId}`} to={`/${props.repSite}/dme/manufacturers/${props.manufacturer.manufacturerId}/${props.manufacturer.manufacturerName.replace(/\s+/g, '-').toLowerCase()}`}>
                {props.manufacturer.manufacturerName}
        </DropDownItem>
    );
}

export default manufacturerMenuItem;