import types from '../ActionTypes';

const initialState = {
    categories: [],
    manufacturers: [],
    states: [],
    organization: null,
    notification: null,
    loaded: false
}

const commonListReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case types.SET_COMMON_LIST:
            return {
                ...state,
                categories: action.payload.categories,
                manufacturers: action.payload.manufacturers,
                states: action.payload.states,
                organization: action.payload.organization,
                notification: action.payload.notification,
                loaded: true
            }

        case types.FETCH_COMMON_LIST:
            return {
                categories: [],
                manufacturers: [],
                states: [],
                organization: null,
                notification: null,
                loaded: false
            }

        default:
            return state;
    }
}

export default commonListReducer;