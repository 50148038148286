import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ConnectedRouter } from 'connected-react-router';
import actions from './Store/Actions';
import { Spinner } from 'reactstrap';
import Home from './Views/Home/Home';
import Header from './Views/Header/header';
import Footer from './Views/Footer/Footer';
import ProductDetail from './Views/Product/productDetail';
import RepListing from './Views/Reps/RepListing';
import Products from './Views/Product/Products';
import MyListPage from './Views/MyList/myListPage';
import SentList from './Views/MyList/SentList';
import MoreInfo from './Views/MoreInfo/moreInfo';
import Thanks from './Views/MoreInfo/Thanks';
import ViewQuote from './Views/Quotes/ViewQuote';
import ApplyForm from './Views/ApplyForm/ApplyForm';
import LandingPage from './Views/Organization/LandingPage';

class App extends Component {
    componentDidMount() {
        const hostname = window && window.location && window.location.hostname;
        this.props.fetchOrganizationId({ prpUrl: hostname });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.organizationId !== this.props.organizationId) {
            this.props.fetchCommonLists({ organizationId: this.props.organizationId });
        }
        if (prevProps.common.loaded !== this.props.common.loaded && this.props.common.loaded) {
            if (this.props.common.organization.hasLandingPage) {
                this.props.history.push('/landing');
            } else if (this.props.history.location.pathname.includes('/landing')) {
                this.props.history.push('/mds');
            }
        }
    }

    render() {
        let body = (
            <div className="text-center mt-5">
                <Spinner color="primary" style={{ width: '6rem', height: '6rem' }} />
            </div>
        );

        if (this.props.common.loaded) {
            body = (
                <ConnectedRouter history={this.props.history}>
                    <div className="App">
                        <Switch>
                            <Route
                                path="/:rep?"
                                render={() => (
                                    <Header
                                        manufacturers={this.props.common.manufacturers}
                                        categories={this.props.common.categories}
                                        organization={this.props.common.organization}
                                    />
                                )}
                            />
                        </Switch>
                        <main role="main" className="container-fluid body-content">
                            <Switch>
                                <Route path="/landing" component={LandingPage} />
                                <Route path="/mds/rep" component={RepListing} />
                                <Route path="/mds/dme/listing/:listId" component={SentList} />
                                <Route path="/apply/:applicationId" component={ApplyForm} />
                                <Route path="/viewquote/:supplierId/:quoteId" component={ViewQuote} />
                                <Route path="/:rep/viewquote/:supplierId/:quoteId" component={ViewQuote} />
                                <Route path="/:rep/mylist" component={MyListPage} />
                                <Route path="/:rep/dme/listing/:listId" component={SentList} />
                                <Route path="/:rep/more-info" component={MoreInfo} />
                                <Route path="/:rep/thanks" component={Thanks} />
                                <Route path="/:rep/dme/detail/:productId/:productName?" component={ProductDetail} />
                                <Route path="/:rep/dme/:filterType?/:filterId?/:filterName?" component={Products} />
                                <Route path="/:rep/carousel" component={Home} />
                                <Route path="/:rep?" component={Products} />
                            </Switch>
                        </main>
                        <Footer organization={this.props.common.organization} />
                    </div>
                </ConnectedRouter>
            );
        }

        return <BrowserRouter>{body}</BrowserRouter>;
    }
}

const mapStateToProps = (state) => {
    return {
        organizationId: state.organizationId.organizationId,
        loaded: state.organizationId.loaded,
        common: state.common,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            fetchOrganizationId: actions.fetchOrganizationId,
            fetchCommonLists: actions.fetchCommonLists,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
