import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchOrganizationId } from '../Services/API';
import types from '../ActionTypes';
import actions from '../Actions'

function* getOrganizationId(action) {
    const response = yield call(fetchOrganizationId, action.payload.prpUrl);
    yield put(actions.setOrganizationId(response.data));
}

export function* organizationIdSaga() {
    yield takeLatest(types.FETCH_ORGANIZATION_ID, getOrganizationId);
}