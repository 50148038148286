import types from '../../Store/ActionTypes';

const initialState = {
    organizationId: 0,
    notification: null,
    loaded: false
}

const organizationIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_ORGANIZATION_ID:
            return {
                ...state,
                organizationId: action.payload.notification.returnId,
                notification: action.payload.notification,
                loaded: true
            }

        case types.FETCH_ORGANIZATION_ID:
            return {
                organizationId: 0,
                notification: null,
                loaded: false
            }
    
        default:
            return state;
    }
}

export default organizationIdReducer;