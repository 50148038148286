import types from '../ActionTypes';

const initialState = {
    products: [],
    count: 0,
    name: '',
    notification: null,
    loaded: false,
}

const sentListReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case types.SET_SENT_LIST:
            return {
                ...state,
                products: action.payload.products,
                count: action.payload.productCount,
                name: action.payload.listName,
                notification: action.payload.notification,
                loaded: true
            }

        case types.GET_SENT_LIST:
            return initialState;

        default:
            return state;
    }
}

export default sentListReducer;