import React, { Component } from 'react';
import { Card, CardBody, Row, Col, CardHeader, CardTitle } from 'reactstrap';
import Icon from '../Icon/icon';
import Style from './IsrCard.module.css';
import { IMAGE_PATH } from '../Shared/Constants/Constants';

class IsrCard extends Component {
    render() {
        const isr = this.props.isr;
        return (
            <Card className={`float-start me-2 mb-2 shadow-sm ${Style.IsrCard}`} style={this.props.style}>
                <CardHeader className="p-2">
                    <CardTitle tag="h4" className="mb-1">
                        {isr.organizationName}
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col xs="24" className="text-center">
                            {isr.logoUrl ? (
                                <img
                                    alt={isr.organizationName}
                                    src={`${IMAGE_PATH}${isr.logoUrl}?anchor=middlecenter&format=jpg&height=80&quality=80`}
                                    className="rounded"
                                    style={{ paddingBottom: '5px' }}
                                />
                            ) : (
                                <div />
                            )}
                        </Col>
                        <Col xs="12" className="float-start">
                            <span className="text-secondary me-2">
                                <Icon icon="envelope" size="lg" />
                            </span>
                            <a href={`mailto:${isr.email}`}>{isr.email}</a>
                        </Col>
                        <Col xs="12" className="float-end text-end">
                            <span className="text-secondary me-2">
                                <Icon icon="globe" size="lg" />
                            </span>
                            <a href={`https://${isr.prpUrl}`} target="_blank" rel="noopener noreferrer">
                                Visit {isr.organizationName}'s Page
                            </a>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }
}

export default IsrCard;
