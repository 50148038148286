import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Moment from 'react-moment';
import { Container, Card, CardBody, Row, Col, Label, Spinner, Button, Input, CardHeader, UncontrolledAlert } from 'reactstrap';
import ResponsiveLabelLg from '../Shared/Responsive/ResponsiveLabelLg';
import * as fn from '../Shared/Functions/FormFunctions';
import Icon from '../Icon/icon';
import actions from '../../Store/Actions';

class ApplyForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            applyData:
                this.props.quote && this.props.quote.onboard
                    ? this.props.quote.onboard
                    : {
                          stagedOnboardId: 0,
                          quoteId: this.props.quote ? this.props.quote.quoteId : 0,
                          organizationTypeId: 1,
                          organizationName: '',
                          taxNumber: '',
                          generalEmail: '',
                          orderEmail: '',
                          phone: '',
                          accountNumber: '',
                          insuranceBillingStateId: '',
                          shippingAddressName: '',
                          shippingLine1: '',
                          shippingLine2: '',
                          shippingCity: '',
                          shippingStateId: null,
                          shippingZipCode: '',
                          billingAddressName: '',
                          billingLine1: '',
                          billingLine2: '',
                          billingCity: '',
                          billingStateId: null,
                          billingZipCode: '',
                          firstName: '',
                          lastName: '',
                          loginEmail: '',
                          contactEmail: '',
                          processed: false,
                          requestSource: 'PRP',
                      },
            saving: false,
            infoExpand: true,
            orgExpand: false,
            adrExpand: false,
            userExpand: false,
            msg: null,
        };
    }

    componentDidUpdate(prevProps) {
        // save application
        if (prevProps.result !== this.props.result && this.props.result.caller === 'MemberApplication') {
            this.saveResult({ ...this.props.result });
        }
    }

    inputHandler = (e) => {
        if (this.state.saving) {
            return;
        }
        this.setState({
            applyData: { ...this.state.applyData, [e.target.name]: e.target.value },
        });
    };

    inputIntHandler = (e) => {
        if (this.state.saving) {
            return;
        }
        this.setState({
            applyData: {
                ...this.state.applyData,
                [e.target.name]: e.target.value ? parseInt(e.target.value) : '',
            },
        });
    };

    digitOnlyHandler = (e) => {
        if (this.state.saving) {
            return;
        }
        if (fn.isDigits(e.target.value)) {
            this.setState({
                applyData: { ...this.state.applyData, [e.target.name]: e.target.value },
            });
        }
    };

    checkboxHandler = (e) => {
        if (this.state.saving) {
            return;
        }
        this.setState({
            [e.target.name]: e.target.checked,
        });
    };

    toggleSection = (val) => {
        this.setState(
            {
                infoExpand: false,
                orgExpand: false,
                adrExpand: false,
                userExpand: false,
            },
            () => {
                this.setState({
                    [val]: !this.state[val],
                });
            }
        );
    };

    copyAddress = () => {
        this.setState({
            applyData: {
                ...this.state.applyData,
                billingAddressName: this.state.applyData.shippingAddressName,
                billingLine1: this.state.applyData.shippingLine1,
                billingLine2: this.state.applyData.shippingLine2,
                billingCity: this.state.applyData.shippingCity,
                billingStateId: this.state.applyData.shippingStateId,
                billingZipCode: this.state.applyData.shippingZipCode,
            },
        });
    };

    saveHandler = () => {
        if (
            !fn.fieldValidCheck('organizationName') ||
            !fn.fieldValidCheck('taxNumber') ||
            this.state.applyData.taxNumber.length !== 9 ||
            !fn.fieldValidCheck('insuranceBillingStateId') ||
            !fn.emailValidCheck('orderEmail') ||
            !fn.emailValidCheck('generalEmail') ||
            !fn.fieldValidCheck('phone') ||
            !fn.fieldValidCheck('accountNumber')
        ) {
            this.toggleSection('orgExpand');
            return false;
        }

        if (!fn.fieldValidCheck('firstName') || !fn.fieldValidCheck('lastName') || !fn.emailValidCheck('loginEmail') || !fn.emailValidCheck('contactEmail')) {
            this.toggleSection('userExpand');
            return false;
        }

        this.setState(
            {
                msg: null,
                saving: true,
            },
            () => {
                this.props.saveApplication({
                    applyData: this.state.applyData,
                });
            }
        );
    };

    saveResult(res) {
        //console.log('res',res)
        // error
        if (res.level > 0) {
            this.setState({
                msg: res.message,
                saving: false,
            });
            return;
        }

        // update/insert
        if (
            ('U' === res.actionType && res.returnId === this.state.applyData.stagedOnboardId) ||
            ('I' === res.actionType && 0 === this.state.applyData.stagedOnboardId && res.returnId > 0)
        ) {
            this.setState(
                {
                    msg: null,
                    saving: false,
                    applyData: {
                        ...this.state.applyData,
                        stagedOnboardId: res.returnId,
                        createdDate: new Date(),
                    },
                },
                () => {
                    this.props.update(this.state.applyData);
                }
            );
            return;
        }
    }

    render() {
        //console.log('state', this.state)
        //console.log('props', this.props)
        const a = this.state.applyData;
        return (
            <Container fluid>
                <Card className="text-start">
                    <CardBody>
                        {this.state.msg && <UncontrolledAlert color="danger">{this.state.msg}</UncontrolledAlert>}

                        <h5 className="overflow-hidden" style={{ paddingTop: '10px' }}>
                            <b>Membership Application</b>
                            <a
                                className="btn btn-outline-info float-end"
                                style={{ marginTop: '-10px' }}
                                href={this.props.mdsRedirect}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Member Sign In
                            </a>
                        </h5>

                        <Card>
                            <CardHeader onClick={() => this.toggleSection('orgExpand')} className="pointer hoverOpacity7">
                                <b>Organization</b>
                                <span className="float-end">{this.state.orgExpand ? <Icon icon="caret-down" /> : <Icon icon="caret-up" />}</span>
                            </CardHeader>
                            <CardBody className={this.state.orgExpand ? '' : 'd-none'}>
                                <Row className="mt-2">
                                    <Col md="24" lg="4" className="d-none d-lg-flex">
                                        <Label className="pt-2">Organization Type</Label>
                                    </Col>
                                    <Col md="24" lg="20">
                                        <ResponsiveLabelLg label="Organization Type" />
                                        <Input
                                            type="select"
                                            id="organizationTypeId"
                                            name="organizationTypeId"
                                            value={a.organizationTypeId}
                                            onChange={this.inputHandler}
                                        >
                                            <option value="1">Clinic</option>
                                            <option value="1">Distributor</option>
                                        </Input>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="24" lg="4" className="d-none d-lg-flex">
                                        <Label className="pt-2">Organization Name</Label>
                                    </Col>
                                    <Col md="24" lg="20">
                                        <ResponsiveLabelLg label="Organization Name" />
                                        <Input
                                            id="organizationName"
                                            name="organizationName"
                                            value={a.organizationName}
                                            onChange={this.inputHandler}
                                            placeholder="Organization Name"
                                            maxLength="50"
                                            required
                                            invalid={!a.organizationName}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="24" lg="4" className="d-none d-lg-flex">
                                        <Label className="pt-2">Tax Number</Label>
                                    </Col>
                                    <Col md="24" lg="20">
                                        <ResponsiveLabelLg label="Tax Number" />
                                        <Input
                                            id="taxNumber"
                                            name="taxNumber"
                                            value={a.taxNumber}
                                            onChange={this.digitOnlyHandler}
                                            placeholder="Tax Number (EIN, 9 digits, no dash or space)"
                                            maxLength="9"
                                            required
                                            invalid={!a.taxNumber || !(a.taxNumber.length === 9)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="24" lg="4" className="d-none d-lg-flex">
                                        <Label className="pt-2">Insurance Billing State</Label>
                                    </Col>
                                    <Col md="24" lg="20">
                                        <ResponsiveLabelLg label="Insurance Billing State" />
                                        <Input
                                            type="select"
                                            id="insuranceBillingStateId"
                                            name="insuranceBillingStateId"
                                            value={a.insuranceBillingStateId}
                                            onChange={this.inputIntHandler}
                                            required
                                            invalid={!a.insuranceBillingStateId}
                                        >
                                            <option value="">Select state..</option>
                                            {this.props.states.map((m) => (
                                                <option value={m.stateId} key={`ibs_${m.stateId}`}>
                                                    {m.stateName}
                                                </option>
                                            ))}
                                        </Input>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="24" lg="4" className="d-none d-lg-flex">
                                        <Label className="pt-2">Email for Orders</Label>
                                    </Col>
                                    <Col md="24" lg="20">
                                        <ResponsiveLabelLg label="Email for Orders" />
                                        <Input
                                            id="orderEmail"
                                            name="orderEmail"
                                            value={a.orderEmail}
                                            onChange={this.inputHandler}
                                            placeholder="Email for Orders"
                                            maxLength="100"
                                            required
                                            invalid={!a.orderEmail || !fn.isEmailPattern(a.orderEmail)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="24" lg="4" className="d-none d-lg-flex">
                                        <Label className="pt-2">General Email</Label>
                                    </Col>
                                    <Col md="24" lg="20">
                                        <ResponsiveLabelLg label="General Email" />
                                        <Input
                                            id="generalEmail"
                                            name="generalEmail"
                                            value={a.generalEmail}
                                            onChange={this.inputHandler}
                                            placeholder="General Email"
                                            maxLength="100"
                                            required
                                            invalid={!a.generalEmail || !fn.isEmailPattern(a.generalEmail)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="24" lg="4" className="d-none d-lg-flex">
                                        <Label className="pt-2">Phone</Label>
                                    </Col>
                                    <Col md="24" lg="20">
                                        <ResponsiveLabelLg label="Phone" />
                                        <Input
                                            id="phone"
                                            name="phone"
                                            value={a.phone}
                                            onChange={this.inputHandler}
                                            placeholder="Phone"
                                            maxLength="20"
                                            required
                                            invalid={!a.phone}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="24" lg="4" className="d-none d-lg-flex">
                                        <Label className="pt-2">Account Number</Label>
                                    </Col>
                                    <Col md="24" lg="20">
                                        <ResponsiveLabelLg label="Account Number" />
                                        <Input
                                            id="accountNumber"
                                            name="accountNumber"
                                            value={a.accountNumber}
                                            onChange={this.inputHandler}
                                            placeholder="Account Number with supplier"
                                            maxLength="20"
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <Card className="mt-2">
                            <CardHeader onClick={() => this.toggleSection('adrExpand')} className="pointer hoverOpacity7">
                                <b>Addresses</b>
                                <span className="float-end">{this.state.adrExpand ? <Icon icon="caret-down" /> : <Icon icon="caret-up" />}</span>
                            </CardHeader>
                            <CardBody className={this.state.adrExpand ? '' : 'd-none'}>
                                <h5>Shipping Address</h5>
                                <Row className="mt-2">
                                    <Col md="24" lg="4" className="d-none d-lg-flex">
                                        <Label className="pt-2">Shipping Address Name</Label>
                                    </Col>
                                    <Col md="24" lg="20">
                                        <ResponsiveLabelLg label="Shipping Address Name" />
                                        <Input
                                            id="shippingAddressName"
                                            name="shippingAddressName"
                                            value={a.shippingAddressName}
                                            onChange={this.inputHandler}
                                            placeholder="Shipping Address Name"
                                            maxLength="100"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="24" lg="4" className="d-none d-lg-flex">
                                        <Label className="pt-2">Shipping Line 1</Label>
                                    </Col>
                                    <Col md="24" lg="20">
                                        <ResponsiveLabelLg label="Shipping Line 1" />
                                        <Input
                                            id="shippingLine1"
                                            name="shippingLine1"
                                            value={a.shippingLine1}
                                            onChange={this.inputHandler}
                                            placeholder="Shipping Line 1"
                                            maxLength="75"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="24" lg="4" className="d-none d-lg-flex">
                                        <Label className="pt-2">Shipping Line 2</Label>
                                    </Col>
                                    <Col md="24" lg="20">
                                        <ResponsiveLabelLg label="Shipping Line 2" />
                                        <Input
                                            id="shippingLine2"
                                            name="shippingLine2"
                                            value={a.shippingLine2}
                                            onChange={this.inputHandler}
                                            placeholder="Shipping Line 2"
                                            maxLength="75"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="24" lg="4" className="d-none d-lg-flex">
                                        <Label className="pt-2">Shipping City</Label>
                                    </Col>
                                    <Col md="24" lg="20">
                                        <ResponsiveLabelLg label="Shipping City" />
                                        <Input
                                            id="shippingCity"
                                            name="shippingCity"
                                            value={a.shippingCity}
                                            onChange={this.inputHandler}
                                            placeholder="Shipping City"
                                            maxLength="50"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="24" lg="4" className="d-none d-lg-flex">
                                        <Label className="pt-2">Shipping State</Label>
                                    </Col>
                                    <Col md="24" lg="20">
                                        <ResponsiveLabelLg label="Shipping State" />
                                        <Input
                                            type="select"
                                            id="shippingStateId"
                                            name="shippingStateId"
                                            value={a.shippingStateId || ''}
                                            onChange={this.inputIntHandler}
                                        >
                                            <option value="">Select state..</option>
                                            {this.props.states.map((m) => (
                                                <option value={m.stateId} key={`ibs_${m.stateId}`}>
                                                    {m.stateName}
                                                </option>
                                            ))}
                                        </Input>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="24" lg="4" className="d-none d-lg-flex">
                                        <Label className="pt-2">Shipping Zip</Label>
                                    </Col>
                                    <Col md="24" lg="20">
                                        <ResponsiveLabelLg label="Shipping Zip" />
                                        <Input
                                            id="shippingZipCode"
                                            name="shippingZipCode"
                                            value={a.shippingZipCode}
                                            onChange={this.digitOnlyHandler}
                                            placeholder="Shipping Zip"
                                            maxLength="5"
                                        />
                                    </Col>
                                </Row>

                                <Row className="mt-2">
                                    <Col md="24">
                                        <Label className="pt-2">
                                            <h5>Billing Address</h5>
                                        </Label>
                                        <Button className="btn-sm float-end" outline color="info" onClick={this.copyAddress}>
                                            Copy
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="24" lg="4" className="d-none d-lg-flex">
                                        <Label className="pt-2">Billing Address Name</Label>
                                    </Col>
                                    <Col md="24" lg="20">
                                        <ResponsiveLabelLg label="Billing Address Name" />
                                        <Input
                                            id="billingAddressName"
                                            name="billingAddressName"
                                            value={a.billingAddressName}
                                            onChange={this.inputHandler}
                                            placeholder="Billing Address Name"
                                            maxLength="100"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="24" lg="4" className="d-none d-lg-flex">
                                        <Label className="pt-2">Billing Line 1</Label>
                                    </Col>
                                    <Col md="24" lg="20">
                                        <ResponsiveLabelLg label="Billing Line 1" />
                                        <Input
                                            id="billingLine1"
                                            name="billingLine1"
                                            value={a.billingLine1}
                                            onChange={this.inputHandler}
                                            placeholder="Billing Line 1"
                                            maxLength="75"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="24" lg="4" className="d-none d-lg-flex">
                                        <Label className="pt-2">Billing Line 2</Label>
                                    </Col>
                                    <Col md="24" lg="20">
                                        <ResponsiveLabelLg label="Billing Line 2" />
                                        <Input
                                            id="billingLine2"
                                            name="billingLine2"
                                            value={a.billingLine2}
                                            onChange={this.inputHandler}
                                            placeholder="Billing Line 2"
                                            maxLength="75"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="24" lg="4" className="d-none d-lg-flex">
                                        <Label className="pt-2">Billing City</Label>
                                    </Col>
                                    <Col md="24" lg="20">
                                        <ResponsiveLabelLg label="Billing City" />
                                        <Input
                                            id="billingCity"
                                            name="billingCity"
                                            value={a.billingCity}
                                            onChange={this.inputHandler}
                                            placeholder="Billing City"
                                            maxLength="50"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="24" lg="4" className="d-none d-lg-flex">
                                        <Label className="pt-2">Billing State</Label>
                                    </Col>
                                    <Col md="24" lg="20">
                                        <ResponsiveLabelLg label="Billing State" />
                                        <Input
                                            type="select"
                                            id="billingStateId"
                                            name="billingStateId"
                                            value={a.billingStateId || ''}
                                            onChange={this.inputIntHandler}
                                        >
                                            <option value="">Select state..</option>
                                            {this.props.states.map((m) => (
                                                <option value={m.stateId} key={`ibs_${m.stateId}`}>
                                                    {m.stateName}
                                                </option>
                                            ))}
                                        </Input>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="24" lg="4" className="d-none d-lg-flex">
                                        <Label className="pt-2">Billing Zip</Label>
                                    </Col>
                                    <Col md="24" lg="20">
                                        <ResponsiveLabelLg label="Billing Zip" />
                                        <Input
                                            id="billingZipCode"
                                            name="billingZipCode"
                                            value={a.billingZipCode}
                                            onChange={this.digitOnlyHandler}
                                            placeholder="Billing Zip"
                                            maxLength="5"
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <Card className="mt-2">
                            <CardHeader onClick={() => this.toggleSection('userExpand')} className="pointer hoverOpacity7">
                                <b>Administrator</b>
                                <span className="float-end">{this.state.userExpand ? <Icon icon="caret-down" /> : <Icon icon="caret-up" />}</span>
                            </CardHeader>
                            <CardBody className={this.state.userExpand ? '' : 'd-none'}>
                                <span>*This is an initial power user for setup purposes, additional admins can be added once membership is established</span>
                                <Row className="mt-2">
                                    <Col md="24" lg="4" className="d-none d-lg-flex">
                                        <Label className="pt-2">First Name</Label>
                                    </Col>
                                    <Col md="24" lg="20">
                                        <ResponsiveLabelLg label="First Name" />
                                        <Input
                                            id="firstName"
                                            name="firstName"
                                            value={a.firstName}
                                            onChange={this.inputHandler}
                                            placeholder="First Name"
                                            maxLength="35"
                                            required
                                            invalid={!a.firstName}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="24" lg="4" className="d-none d-lg-flex">
                                        <Label className="pt-2">Last Name</Label>
                                    </Col>
                                    <Col md="24" lg="20">
                                        <ResponsiveLabelLg label="Last Name" />
                                        <Input
                                            id="lastName"
                                            name="lastName"
                                            value={a.lastName}
                                            onChange={this.inputHandler}
                                            placeholder="Last Name"
                                            maxLength="35"
                                            required
                                            invalid={!a.lastName}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="24" lg="4" className="d-none d-lg-flex">
                                        <Label className="pt-2">Login Email</Label>
                                    </Col>
                                    <Col md="24" lg="20">
                                        <ResponsiveLabelLg label="Login Email" />
                                        <Input
                                            id="loginEmail"
                                            name="loginEmail"
                                            value={a.loginEmail}
                                            onChange={this.inputHandler}
                                            placeholder="Login Email"
                                            maxLength="100"
                                            required
                                            invalid={!a.loginEmail || !fn.isEmailPattern(a.loginEmail)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md="24" lg="4" className="d-none d-lg-flex">
                                        <Label className="pt-2">Contact Email</Label>
                                    </Col>
                                    <Col md="24" lg="20">
                                        <ResponsiveLabelLg label="Contact Email" />
                                        <Input
                                            id="contactEmail"
                                            name="contactEmail"
                                            value={a.contactEmail}
                                            onChange={this.inputHandler}
                                            placeholder="Contact Email"
                                            maxLength="100"
                                            required
                                            invalid={!a.contactEmail || !fn.isEmailPattern(a.contactEmail)}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        {/*
                        <Card className='mt-2'>
                            <CardHeader onClick={() => this.toggleSection('infoExpand')} className='pointer hoverOpacity7'>
                                <b>Membership</b>
                                <span className='float-end'>
                                    {this.state.infoExpand 
                                    ? <Icon icon='caret-down' />
                                    : <Icon icon='caret-up' />
                                    }
                                </span>
                            </CardHeader>                        
                            <CardBody className={this.state.infoExpand ? '' : 'd-none'}>
                                <div>... TODO: Membership agreement/disclaimer/subscription/cost stuff goes here</div>
                                <div>Sponsorship Offered: {this.props.quote.offerSponsorship ? 'Yes' : 'No'}</div>
                            </CardBody>
                        </Card>
                        */}

                        <Row className="mt-2">
                            <Col md="24">
                                {a.processedDate ? (
                                    <span color="success">
                                        Processed <Moment date={a.processedDate} format="MM/DD/YYYY" local="true" />
                                    </span>
                                ) : (
                                    <Button color="success" outline onClick={this.saveHandler}>
                                        {this.state.saving ? (
                                            <React.Fragment>
                                                <Spinner size="sm" /> Saving
                                            </React.Fragment>
                                        ) : a.createdDate ? (
                                            <React.Fragment>
                                                <Icon icon="save" type="far" /> Resubmit
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <Icon icon="save" type="far" /> Submit
                                            </React.Fragment>
                                        )}
                                    </Button>
                                )}
                                {a.createdDate && !a.processedDate && (
                                    <span className="ms-2">
                                        Submitted <Moment date={a.createdDate} format="MM/DD/YYYY" local="true" />
                                    </span>
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    states: state.common.states,
    result: state.genericResult,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            saveApplication: actions.saveApplication,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplyForm);
