import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Row, Col, Input, Button } from 'reactstrap';

import Icon from '../Icon/icon';

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchString: '',
        };
    }

    componentDidMount() {
        this.updateSearchString();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            this.updateSearchString();
        }
    }

    onSearchStringChange = (e) => {
        this.setState({
            searchString: e.target.value,
        });
    };

    search = () => {
        if (this.props.currentRep) {
            this.props.history.push(`/${this.props.currentRep.siteName}/dme?term=${this.state.searchString}`);
        } else {
            this.props.history.push(`/mds/dme?term=${this.state.searchString}`);
        }
    };
    clearSearch = () => {
        if (this.props.currentRep) {
            this.props.history.push(`/${this.props.currentRep.siteName}/dme`);
        } else {
            this.props.history.push(`/mds/dme`);
        }
    };

    updateSearchString = () => {
        if (this.props.location.search) {
            this.setState({
                searchString: this.props.location.search.split('=')[1],
            });
        } else {
            this.setState({
                searchString: '',
            });
        }
    };

    render() {
        return (
            <Row className="pb-1">
                <Col xs="12">
                    <Input
                        type="text"
                        name="searchString"
                        id="searchString"
                        value={this.state.searchString}
                        placeholder="Product, Manufacturer or HCPCS"
                        onChange={(e) => this.onSearchStringChange(e)}
                    />
                </Col>
                <Col xs="12">
                    <Button color="success" outline className="btn-sm" onClick={this.search}>
                        <Icon icon="search" /> Search
                    </Button>
                    {this.state.searchString && (
                        <Button color="danger" outline className="btn-sm ms-2" onClick={this.clearSearch}>
                            <Icon icon="times" /> Clear
                        </Button>
                    )}
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentRep: state.rep.rep,
    };
};

export default connect(mapStateToProps)(withRouter(Search));
