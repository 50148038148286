import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, Button } from 'reactstrap';
import Icon from '../Icon/icon';
import Toolbar from '../Toolbar/Toolbar';

class Thanks extends Component {
    navigationButtonClickHandler = (url) => {
        this.props.history.push(url);
    };

    render() {
        return (
            <React.Fragment>
                <Toolbar showBrowse />
                <Card>
                    <CardBody>
                        <h1 className="display-5">Your inquiry was submitted.</h1>
                        <p className="lead">Someone will contact you shortly.</p>
                        <hr className="my-4" />
                        <p>Use the buttons below to continue.</p>
                        <Button color="primary" onClick={() => this.navigationButtonClickHandler('/')}>
                            <Icon icon="home" /> Return Home
                        </Button>
                        <Button color="primary" onClick={() => this.navigationButtonClickHandler('/mds/dme')} className="ms-3">
                            <Icon icon="book-open" /> Browse Catalog
                        </Button>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default withRouter(Thanks);
