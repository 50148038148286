import types from '../ActionTypes';

const initialState = {
    level: null,
    message: null,
    returnId: null,
    returnObject: null,
    actionType: null,
};

const genericResultReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.DECLINE_QUOTE_COMPLETE:
        case types.SAVE_APPLICATION_COMPLETE:
            return {
                ...state,
                level: action.payload.notification.level,
                message: action.payload.notification.message,
                returnId: action.payload.notification.returnId,
                returnObject: action.payload.notification.returnObject,
                actionType: action.payload.notification.actionType,
                caller: action.payload.notification.caller,
            };
        default:
            return state;
    }
};

export default genericResultReducer;
