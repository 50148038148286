import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../Store/Actions';
import ToggleControl from '../Shared/FormInputs/ToggleControl';
import { Row, Col, Card, CardHeader, CardBody, CardTitle, Input, Label, Form, FormGroup, Button, Spinner, UncontrolledAlert } from 'reactstrap';
import * as fn from '../Shared/Functions/FormFunctions';
import Toolbar from '../Toolbar/Toolbar';

class MoreInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formValid: false,
            formSubmitted: false,
            contactId: 0,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            companyName: '',
            jobTitle: '',
            zipCode: '',
            state: '0',
            contactType: false, // Not an existing customer
            userId: 0,
            organizationId: 0,
            leadRepId: this.props.rep ? this.props.rep.userId : 0,
            showReps: false,
            msg: '',
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.result.completed !== this.props.result.completed && !this.props.result.pending && this.props.result.notification) {
            if (this.props.result.notification.level > 0) {
                this.setState({
                    msg: this.props.result.notification.message,
                });
                return;
            }
            this.props.history.push('/mds/thanks');
        }
    }

    inputHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    numericInputHandler = (e) => {
        if (!fn.isDigits(e.target.value)) {
            return;
        }
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    checkBoxHandler = (e) => {
        this.setState({
            showReps: e.target.checked,
            [e.target.name]: e.target.checked,
        });
    };

    formIsValid = () => {
        return (
            this.state.firstName.length > 0 &&
            this.state.lastName.length > 0 &&
            this.state.companyName.length > 0 &&
            this.state.state !== '0' &&
            this.state.email.length > 0 &&
            fn.isEmailPattern(this.state.email)
        );
    };

    saveForm = () => {
        this.setState(
            {
                formSubmitted: true,
            },
            () => {
                this.props.saveContact({
                    contact: {
                        contactId: this.state.contactId,
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        email: this.state.email,
                        phone: this.state.phone.replace(/\s+/g, ''),
                        companyName: this.state.companyName,
                        jobTitle: this.state.jobTitle,
                        zipCode: this.state.zipCode,
                        stateId: parseInt(this.state.state),
                        contactTypeId: this.state.contactType ? 1 : 2,
                        organizationId: this.props.common.organization.organizationId,
                        leadRepId: parseInt(this.state.leadRepId),
                        products: this.props.mylist ? this.props.mylist.map((m) => m.productId) : [],
                    },
                });
            }
        );
    };

    render() {
        return (
            <React.Fragment>
                <Toolbar />
                <Card>
                    <CardHeader>
                        <CardTitle tag="h4">Request for More Information</CardTitle>
                    </CardHeader>
                    <CardBody>
                        {this.state.msg && <UncontrolledAlert color="danger">{this.state.msg}</UncontrolledAlert>}
                        <Form>
                            <Row>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="firstName">First Name</Label>
                                        <Input
                                            name="firstName"
                                            id="firstName"
                                            onChange={this.inputHandler}
                                            value={this.state.firstName || ''}
                                            invalid={!this.state.firstName}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="lastName">Last Name</Label>
                                        <Input
                                            name="lastName"
                                            id="lastName"
                                            onChange={this.inputHandler}
                                            value={this.state.lastName || ''}
                                            invalid={!this.state.lastName}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="email">Email</Label>
                                        <Input
                                            type="email"
                                            name="email"
                                            id="email"
                                            onChange={this.inputHandler}
                                            value={this.state.email || ''}
                                            invalid={!this.state.email || !fn.isEmailPattern(this.state.email)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="phone">Phone</Label>
                                        <Input
                                            type="tel"
                                            name="phone"
                                            id="phone"
                                            maxLength="10"
                                            onChange={this.numericInputHandler}
                                            value={this.state.phone || ''}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="companyName">Company Name</Label>
                                        <Input
                                            name="companyName"
                                            id="companyName"
                                            onChange={this.inputHandler}
                                            value={this.state.companyName || ''}
                                            invalid={!this.state.companyName}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="jobTitle">Job Title</Label>
                                        <Input name="jobTitle" id="jobTitle" maxLength="100" onChange={this.inputHandler} value={this.state.jobTitle || ''} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="border-bottom">
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="state">State</Label>
                                        <Input
                                            type="select"
                                            name="state"
                                            id="state"
                                            onChange={this.inputHandler}
                                            value={this.state.state || ''}
                                            invalid={this.state.state === '0'}
                                        >
                                            <option value="0" key="0">
                                                Select state...
                                            </option>
                                            {this.props.loaded &&
                                                this.props.states.map((state) => {
                                                    return (
                                                        <option value={state.stateId} key={state.stateId}>
                                                            {state.stateName}
                                                        </option>
                                                    );
                                                })}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="zipCode">Zip Code</Label>
                                        <Input name="zipCode" id="zipCode" maxLength="5" onChange={this.numericInputHandler} value={this.state.zipCode || ''} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="border-bottom py-3">
                                <Col>
                                    <ToggleControl
                                        type="checkbox"
                                        label="Check here if you are an existing customer."
                                        name="contactType"
                                        id="contactType"
                                        onChange={this.checkBoxHandler}
                                        checked={this.state.contactType}
                                    />
                                </Col>
                            </Row>
                            <Row className={`${!this.state.showReps ? 'd-none' : 'd-block'} border-bottom py-3`}>
                                <Col>
                                    <FormGroup>
                                        <Label for="leadRepId">Sales Rep</Label>
                                        <Input type="select" name="leadRepId" id="leadRepId" onChange={this.inputHandler} value={this.state.leadRepId || ''}>
                                            <option value="0" key="0">
                                                Select representative...
                                            </option>
                                            {this.props.reps &&
                                                this.props.reps.map((rep) => {
                                                    return (
                                                        <option value={rep.userId} key={rep.userId}>
                                                            {rep.fullName}
                                                        </option>
                                                    );
                                                })}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="py-3">
                                <Col className="text-end">
                                    {this.state.formSubmitted ? (
                                        <Button color="primary" outline disabled>
                                            <Spinner color="primary" size="sm" /> Sending your info
                                        </Button>
                                    ) : (
                                        <Button color="success" disabled={!this.formIsValid()} onClick={this.saveForm}>
                                            Request Info
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    states: state.common.states,
    loaded: state.common.loaded,
    reps: state.reps.reps,
    rep: state.rep.rep,
    common: state.common,
    mylist: state.myList.mylist,
    result: state.saveContact,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            saveContact: actions.saveContact,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MoreInfo));
