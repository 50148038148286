import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'; 

import {
    Button
} from 'reactstrap';
import Icon from '../Icon/icon';

class BrowseCatalog extends Component {

    browseButtonClickHandler = () => {
        if(this.props.currentRep){
            this.props.history.push(`/${this.props.currentRep.siteName}/dme`);
        } else {
            this.props.history.push(`/mds/dme`);
        }
    }

    render () {
        return (
            <Button color="primary" onClick={() => this.browseButtonClickHandler()}>
                <Icon icon="book-open" /> Browse Catalog
            </Button>
        );
    }
}

const mapStateToProps = state => {
    return {
      currentRep: state.rep.rep
    }
  }

export default connect(mapStateToProps)(withRouter(BrowseCatalog));