import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const icon = (props) => {
    return (
        <FontAwesomeIcon
            className={props.className}
            icon={[props.type || 'fas', props.icon]}
            size={props.size || 'sm'}
            inverse={props.inverse} 
            color={props.color}  />
    );
}

export default icon;