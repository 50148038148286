import types from '../../Store/ActionTypes';

const initialState = {
    reps: [],
    notification: null,
    loaded: false
}

const repsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_REPS:
            return {
                ...state,
                reps: action.payload.reps,
                notification: action.payload.notification,
                loaded: true
            }

        case types.FETCH_REPS:
            return {
                reps: [],
                notification: null,
                loaded: false
            }
    
        default:
            return state;
    }
}

export default repsReducer;