import { takeEvery, call, put } from 'redux-saga/effects';
import { apiSaveApplication } from '../Services/API';
import types from '../ActionTypes';
import actions from '../Actions';

// save application
function* callSaveApplication(action){
    const response = yield call(apiSaveApplication, action.payload.applyData);
    yield put(actions.saveApplicationComplete(response.data));
}
export function* saveApplicationSaga() {
    yield takeEvery(types.SAVE_APPLICATION, callSaveApplication);
}
