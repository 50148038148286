import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../../Store/Actions';
import ToggleControl from '../Shared/FormInputs/ToggleControl';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, Button, Spinner, Collapse } from 'reactstrap';
import Toolbar from '../Toolbar/Toolbar';
import ProductCard from './productCard';
import Icon from '../Icon/icon';
import ProductQuickViewModal from '../Product/ProductQuickViewModal';
import { Pager } from '@progress/kendo-react-data-tools';

class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            modal: false,
            showFilters: true,
            quickViewProduct: null,
            skip: 0,
            take: 12,
            manufacturers: [],
            categories: [],
            tags: [],
            search: '',
        };
    }

    componentDidMount() {
        this.parseUrlFilters();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match !== this.props.match || prevProps.location.search !== this.props.location.search) {
            this.parseUrlFilters();
        }
    }

    toggle = () => {
        this.setState({ collapse: !this.state.collapse });
    };

    toggleModal = () => {
        this.setState({ modal: !this.state.modal });
    };

    quickView = (product) => {
        this.setState(
            {
                quickViewProduct: product,
            },
            () => {
                this.toggleModal();
            }
        );
    };

    clearAllFilters = () => {
        this.setState(
            {
                manufacturers: [],
                categories: [],
                tags: [],
                search: '',
            },
            () => {
                this.getProducts(true);
            }
        );
    };

    checkBoxHandler = (e) => {
        const id = parseInt(e.target.value);
        this.setState(
            {
                [e.target.name]: e.target.checked ? [...this.state[e.target.name], id] : this.state[e.target.name].filter((f) => id !== f),
            },
            () => {
                this.getProducts(true);
            }
        );
    };

    removeSearchFilter = () => {
        this.setState(
            {
                search: '',
            },
            () => {
                this.getProducts(true);
            }
        );
    };

    parseUrlFilters = () => {
        this.setState(
            {
                manufacturers: [],
                categories: [],
                tags: [],
                search: this.props.location.search ? this.props.location.search.split('=')[1] : '',
            },
            () => {
                let filterId = this.props.match.params.filterId;
                let filterType = this.props.match.params.filterType;
                if (filterType && filterId && this.state[filterType]) {
                    this.setState(
                        {
                            [filterType]: [...this.state[filterType], parseInt(filterId)],
                        },
                        () => {
                            this.getProducts(true);
                        }
                    );
                } else {
                    this.getProducts(true);
                }
            }
        );
    };

    getProducts = (resetPages) => {
        this.setState(
            {
                skip: resetPages ? 0 : this.state.skip,
            },
            () => {
                this.props.fetchProducts({
                    params: {
                        supplierIds: [this.props.common.organization.organizationId],
                        manufacturerIds: this.state.manufacturers,
                        categoryTagIds: this.state.categories,
                        productTagIds: this.state.tags,
                        search: this.state.search,
                        skip: this.state.skip,
                        take: this.state.take,
                    },
                });
            }
        );
    };

    handlePageChange = (e) => {
        this.setState(
            {
                skip: e.skip,
                take: e.take,
            },
            () => {
                this.getProducts(false);
            }
        );
    };

    render() {
        return (
            <React.Fragment>
                <Toolbar />
                <Card>
                    <CardHeader>
                        <CardTitle tag="h4">
                            <Icon icon="book-open" /> Catalog
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col lg="4">
                                <Card className="mb-3">
                                    <CardHeader>
                                        <Row>
                                            <Col>
                                                <CardTitle tag="h4" className="mb-0">
                                                    Filters
                                                </CardTitle>
                                            </Col>
                                            <Col className="text-end d-lg-none">
                                                <Button color="light" onClick={this.toggleFilters}>
                                                    <Icon icon="bars" />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        {this.state.search && (
                                            <React.Fragment>
                                                <h6>Search</h6>
                                                <p>{this.state.search}</p>
                                                <hr />
                                            </React.Fragment>
                                        )}

                                        {this.props.common.loaded && (
                                            <React.Fragment>
                                                {this.props.common.manufacturers.length > 1 && (
                                                    <React.Fragment>
                                                        <h6 className="fw-bold">Manufacturers</h6>
                                                        {this.props.common.manufacturers.map((m) => (
                                                            <ToggleControl
                                                                type="checkbox"
                                                                name="manufacturers"
                                                                label={m.manufacturerName}
                                                                key={m.manufacturerId}
                                                                id={m.manufacturerName}
                                                                onChange={this.checkBoxHandler}
                                                                value={m.manufacturerId}
                                                                checked={this.state.manufacturers.includes(m.manufacturerId)}
                                                            />
                                                        ))}
                                                        <hr />
                                                    </React.Fragment>
                                                )}

                                                {this.props.common.categories.length > 1 && (
                                                    <React.Fragment>
                                                        <h6 className="fw-bold">Categories</h6>
                                                        {this.props.common.categories
                                                            .filter((f) => f.tagTypeId === 2)
                                                            .map((m) => (
                                                                <ToggleControl
                                                                    type="checkbox"
                                                                    name="categories"
                                                                    label={m.tagName}
                                                                    key={m.tagId}
                                                                    id={m.tagName}
                                                                    onChange={this.checkBoxHandler}
                                                                    value={m.tagId}
                                                                    checked={this.state.categories.includes(m.tagId)}
                                                                />
                                                            ))}
                                                    </React.Fragment>
                                                )}

                                                {this.props.common.categories.filter((f) => f.tagTypeId === 3).length > 1 ? (
                                                    <React.Fragment>
                                                        <Collapse isOpen={this.state.collapse}>
                                                            {this.props.common.categories
                                                                .filter((f) => f.tagTypeId === 3)
                                                                .map((m) => (
                                                                    <ToggleControl
                                                                        type="checkbox"
                                                                        name="categories"
                                                                        label={m.tagName}
                                                                        key={m.tagId}
                                                                        id={m.tagName}
                                                                        onChange={this.checkBoxHandler}
                                                                        value={m.tagId}
                                                                        checked={this.state.categories.includes(m.tagId)}
                                                                    />
                                                                ))}
                                                        </Collapse>
                                                        <Button color="link" onClick={this.toggle} className="pt-0 mt-0 ps-4">
                                                            {this.state.collapse ? 'Show Less...' : 'Show More...'}
                                                        </Button>
                                                        <hr />
                                                    </React.Fragment>
                                                ) : (
                                                    <hr />
                                                )}

                                                <h6 className="fw-bold">Tags</h6>
                                                <ToggleControl
                                                    type="checkbox"
                                                    label="Best Selling"
                                                    id="bestsellers"
                                                    onChange={this.checkBoxHandler}
                                                    name="tags"
                                                    value={77}
                                                />
                                                <ToggleControl
                                                    type="checkbox"
                                                    label="Featured"
                                                    id="recommended"
                                                    onChange={this.checkBoxHandler}
                                                    name="tags"
                                                    value={78}
                                                />
                                            </React.Fragment>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        {this.props.products.loaded ? (
                                            this.props.products.products.map((m) => <ProductCard product={m} key={m.productId} quickView={this.quickView} />)
                                        ) : (
                                            <Spinner />
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Pager
                                            skip={this.state.skip}
                                            take={this.state.take}
                                            buttonCount={3}
                                            total={this.props.products.count || 0}
                                            pageSizes={[12, 24, 48, 96]}
                                            previousNext={true}
                                            onPageChange={this.handlePageChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <ProductQuickViewModal isOpen={this.state.modal} toggle={this.toggleModal} product={this.state.quickViewProduct} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        common: state.common,
        products: state.products,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            fetchProducts: actions.fetchProducts,
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(Products);
