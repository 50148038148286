import React from 'react';
import { NavLink } from 'react-router-dom';
import { DropdownItem } from 'reactstrap';

const dropDownItem = (props) => {
    return (
        <NavLink to={props.to} className="text-decoration-none">
            <DropdownItem>{props.children}</DropdownItem>
        </NavLink>
    );
}

export default dropDownItem;