import {actionCreator} from 'redux-action-creator';
import types from './ActionTypes';

const actions = {
    fetchOrganizationId: actionCreator(types.FETCH_ORGANIZATION_ID, 'prpUrl'),
    setOrganizationId: actionCreator(types.SET_ORGANIZATION_ID, 'organizationId', 'notification'),
    fetchCommonLists: actionCreator(types.FETCH_COMMON_LIST, 'organizationId'),
    setCommonLists: actionCreator(types.SET_COMMON_LIST, 'categories', 'manufacturers', 'states', 'organization', 'notification'),
    setToMylist: actionCreator(types.SET_TO_MYLIST, 'mylist'),
    clearMylist: actionCreator(types.CLEAR_MYLIST),
    fetchProducts: actionCreator(types.FETCH_PRODUCTS, 'params'),
    setProducts: actionCreator(types.SET_PRODUCTS, 'products', 'productCount', 'notification'),
    fetchProduct: actionCreator(types.FETCH_PRODUCT, 'productId', 'organizationId'),
    setProduct: actionCreator(types.SET_PRODUCT, 'product', 'notification'),
    fetchReps: actionCreator(types.FETCH_REPS, 'organizationId'),
    fetchIsrs: actionCreator(types.FETCH_ISRS, 'organizationId'),
    setIsrs: actionCreator(types.SET_ISRS, 'isrs', 'notification'),
    setReps: actionCreator(types.SET_REPS, 'reps', 'notification'),
    setRep: actionCreator(types.SET_REP, 'rep'),
    saveContact: actionCreator(types.SAVE_CONTACT, 'contact'),
    saveContactStarted: actionCreator(types.SAVE_CONTACT_STARTED),
    saveContactCompleted: actionCreator(types.SAVE_CONTACT_COMPLETED, 'notification'),
    getSentList: actionCreator(types.GET_SENT_LIST, 'params'),
    setSentList: actionCreator(types.SET_SENT_LIST, 'products', 'productCount', 'listName', 'notification'),
    getQuote: actionCreator(types.GET_QUOTE, 'supplierId', 'quoteId'),
    setQuote: actionCreator(types.SET_QUOTE, 'quote', 'notification'),
    declineQuote: actionCreator(types.DECLINE_QUOTE, 'quoteResponse'),
    declineQuoteComplete: actionCreator(types.DECLINE_QUOTE_COMPLETE, 'notification'),
    saveApplication: actionCreator(types.SAVE_APPLICATION, 'applyData'),
    saveApplicationComplete: actionCreator(types.SAVE_APPLICATION_COMPLETE, 'notification'),
    getLandingPage: actionCreator(types.GET_LANDING_PAGE, 'organizationId'),
    setLandingPage: actionCreator(types.SET_LANDING_PAGE, 'content')
};

export default actions;