import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'; 
import {
    Button
} from 'reactstrap';
import Icon from '../Icon/icon';

class ProductInfoButton extends Component {

    infoClickHandler = (id, name) => {
        if(this.props.currentRep){
            this.props.history.push(`/${this.props.currentRep.siteName}/dme/detail/${id}/${name}`);
        } else {
            this.props.history.push(`/mds/dme/detail/${id}/${name}`);
        }
        //this.props.toggle();
    }

    render () {
        return (
            <Button color="primary" size="sm" 
                onClick={() => this.infoClickHandler(this.props.productId, this.props.productName)}
            >
                <Icon icon="exclamation-circle" /> Info
            </Button>
        )
    }
}

const mapStateToProps = state => {
    return {
      currentRep: state.rep.rep
    }
  }

export default connect(mapStateToProps)(withRouter(ProductInfoButton));