import types from '../../Store/ActionTypes';

const initialState = {
    completed: false,
    pending: false,
    notification: null
}

const saveContactReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case types.SAVE_CONTACT_STARTED:
            return {
                ...state,
                completed: false,
                pending: true,
                notification: null
            }

        case types.SAVE_CONTACT_COMPLETED:
            return {
                ...state,
                completed: true,
                pending: false,
                notification: action.payload.notification
            }
        
        case types.SAVE_CONTACT:
            return {
                ...state,
                completed: false,
                pending: false,
                notification: null
            }

        default:
            return state;
    }
}

export default saveContactReducer;