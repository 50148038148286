import React from 'react';
import { Row, Col, Card, CardImg, CardTitle, CardBody } from 'reactstrap';
import Icon from '../Icon/icon';
import { IMAGE_PATH } from '../Shared/Constants/Constants';

const myListOrganizationCard = (props) => {
    return (
        <Card className="shadow-sm mb-3">
            <CardBody>
                <Row>
                    <Col xs="6">
                        <CardImg src={`${IMAGE_PATH}${props.organization.organizationLogoUrl}`} width="100%" alt={props.organization.organizationName} />
                    </Col>
                    <Col xs={{ size: 16, offset: 2 }}>
                        <CardTitle>{props.organization.organizationName}</CardTitle>
                        <Row>
                            <Col>
                                {props.organization.street1}
                                {props.organization.street2 ? ', ' : ''}
                                {props.organization.street2}
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col>
                                {props.organization.city}, {props.organization.stateName} {props.organization.zipcode}
                                {props.organization.zipcodeExt ? '-' : ''}
                                {props.organization.zipcodeExt}
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col>
                                <span className="text-secondary me-2">
                                    <Icon icon="phone" size="lg" />
                                </span>
                                <a href={`tel:${props.organization.organizationPhoneNumber}`}>{props.organization.organizationPhoneNumber}</a>
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col>
                                <span className="text-secondary me-2">
                                    <Icon icon="envelope" size="lg" />
                                </span>
                                <a href={`mailto:${props.organization.email}`}>{props.organization.email}</a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default myListOrganizationCard;
