import React from 'react';

import {
    Row,
    Col
} from 'reactstrap';

import Search from '../Search/Search';
import MyList from '../MyList/MyList';
import BrowseCatalog from '../Search/BrowseCatalog';

const toolbar = (props) => {

    return (
        <Row className="border-bottom mb-3">
            <Col xs="12">
                <Search />
            </Col>
            <Col xs="6">
                {props.showBrowse && <BrowseCatalog />}
            </Col>
            <Col xs="6">
                <MyList />
            </Col>
        </Row>
    )
}

export default toolbar;