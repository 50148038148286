import types from '../../Store/ActionTypes';

const initialState = {
    mylist: [],
    count: 0
}

const repReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_TO_MYLIST:
            return {
                ...state,
                mylist: action.payload.mylist,
                count: action.payload.mylist.length
            }

        case types.CLEAR_MYLIST:
            return {
                ...state,
                mylist: [],
                count: 0
            }
            
        
        default:
            return state;
    }
}

export default repReducer;