import types from '../../Store/ActionTypes';

const initialState = {
    isrs: [],
    notification: null,
    loaded: false
}

const isrsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_ISRS:
            return {
                ...state,
                isrs: action.payload.isrs,
                notification: action.payload.notification,
                loaded: true
            }
        
        case types.FETCH_ISRS:
            return {
                isrs:[],
                notification: null,
                loaded: false
            }

        default:
            return state;
    }
}

export default isrsReducer;