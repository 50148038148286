import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    Row,
    Col,
    Button,
    Badge,
    Card,
    CardBody,
    CardTitle,
    CardImg,
    CardSubtitle,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledTooltip,
} from 'reactstrap';

import Icon from '../Icon/icon';
import Styles from './ProductCard.module.css';
import ProductToggle from '../MyList/ProductToggle';
import ProductInfoButton from './productInfoButton';
import { IMAGE_PATH } from '../Shared/Constants/Constants';

class ProductCard extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false,
        };
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    }

    billingCodeClickHandler = (billingCode) => {
        if (this.props.currentRep) {
            this.props.history.push(`/${this.props.currentRep.siteName}/dme?term=${billingCode}`);
        } else {
            this.props.history.push(`/mds/dme?term=${billingCode}`);
        }
    };

    render() {
        return (
            <Card style={{ width: 335, height: 340 }} className="float-start me-2 mb-2 shadow-sm">
                {this.props.product.productTags && this.props.product.productTags.length > 0 && (
                    <div className={`shadow-sm ${Styles.TopRightIcons}`}>
                        {this.props.product.productTags.map((m) => (
                            <div
                                id={`icon-${m.tagId}-${this.props.product.productId}`}
                                className={` ${77 === m.tagId ? Styles.Fire : 78 === m.tagId ? Styles.Star : ''}`}
                                key={`icon-${m.tagId}-${this.props.product.productId}`}
                            >
                                <Icon icon={`${77 === m.tagId ? 'fire' : 78 === m.tagId ? 'star' : ''}`} size="lg" />
                                <UncontrolledTooltip placement="top" target={`icon-${m.tagId}-${this.props.product.productId}`}>
                                    {m.tagName}
                                </UncontrolledTooltip>
                            </div>
                        ))}
                    </div>
                )}
                <CardBody>
                    <CardTitle tag="h5" style={{ height: 36 }}>
                        {this.props.product.productNameTlc}
                    </CardTitle>
                    <Button color="link" className={`${Styles.QuickButton} p-0`} onClick={() => this.props.quickView(this.props.product)}>
                        {this.props.product.mainPhotoUrl ? (
                            <CardImg
                                width="100%"
                                height="150"
                                src={`${IMAGE_PATH}${this.props.product.mainPhotoUrl}?anchor=middlecenter&mode=pad&format=jpg&height=150&width=300&quality=80`}
                                className={`${Styles.CardImage} border`}
                            />
                        ) : (
                            <div className="text-center" style={{ width: 300 }}>
                                <CardImg width="100%" height="150" src={`/images/no_image.jpg`} className={`${Styles.CardImage} border`} />
                            </div>
                        )}
                        <div className={Styles.Middle}>
                            <div className={Styles.QuickText}>Quick View</div>
                        </div>
                    </Button>
                    <CardSubtitle className="text-center mt-1" tag="h6">
                        {this.props.product.manufacturerLogo ? (
                            <img
                                height="20"
                                src={`${IMAGE_PATH}${this.props.product.manufacturerLogo}?anchor=middlecenter&mode=pad&format=jpg&height=20&width=20&quality=80`}
                                className="me-1"
                                alt={this.props.manufacturerName}
                            />
                        ) : (
                            <img height="20" src={`/images/no_image.jpg`} className="me-1" alt={this.props.manufacturerName} />
                        )}

                        {this.props.product.manufacturerName}
                    </CardSubtitle>
                    <hr />
                    <Row>
                        <Col sm="8">
                            <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} direction="up">
                                <DropdownToggle color="secondary" size="sm" caret>
                                    <Icon icon="prescription-bottle" /> HCPCS
                                </DropdownToggle>
                                <DropdownMenu>
                                    {this.props.product.productBillingCodes && this.props.product.productBillingCodes.length > 0 ? (
                                        this.props.product.productBillingCodes.map((productBillingCode) => (
                                            <DropdownItem
                                                key={productBillingCode.productBillingCodeId}
                                                onClick={() => this.billingCodeClickHandler(productBillingCode.billingCode)}
                                            >
                                                {productBillingCode.billingCode} <Badge color="secondary">{productBillingCode.billingTypeCode}</Badge>
                                            </DropdownItem>
                                        ))
                                    ) : (
                                        <DropdownItem>N/A</DropdownItem>
                                    )}
                                </DropdownMenu>
                            </ButtonDropdown>
                        </Col>
                        <Col className="text-center" sm="8">
                            <ProductToggle product={this.props.product} />
                        </Col>
                        <Col className="text-end" sm="8">
                            <ProductInfoButton productId={this.props.product.productId} productName={this.props.product.productName} />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentRep: state.rep.rep,
    };
};

export default connect(mapStateToProps)(withRouter(ProductCard));
