import types from '../ActionTypes';

const initialState = {
    quote: null,
    notification: null,
    loaded: false
}

const quoteReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_QUOTE:
           return {
               ...state,
               quote: action.payload.quote,
               notification: action.payload.notification,
               loaded: true
           }
        case types.GET_QUOTE:
            return initialState
        default:
            return state;
    }
}

export default quoteReducer;